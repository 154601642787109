const completedItems = [
  3041, 3011, 3107, 3109, 3222, 3504, 4643, 6616, 3050, 3102, 3135, 3157, 3165, 3004, 3042, 3043, 3001, 3033, 3046, 3075, 3094,
  3095, 3110, 3143, 6609, 3026, 3124, 3179, 6695, 3036, 3065, 3139, 3508, 3742, 3814, 4401, 3003, 3040, 3048, 3083, 3100, 3115,
  3116, 3142, 3181, 4628, 4629, 4637, 6035, 6676, 3053, 3091, 3156, 6333, 3153, 3071, 3074, 3193, 3748, 3031, 3072, 3085, 6675,
  6694, 3089, 4403,
];

const ornnItems = [
  7000, 7001, 7002, 7003, 7004, 7005, 7006, 7007, 7008, 7009, 7010, 7011, 7012, 7013, 7014, 7015, 7016, 7017, 7018, 7019, 7020,
  7021, 7022,
];

const mythicItems = [
  2065, 3190, 4005, 6617, 3068, 3152, 4633, 4636, 6662, 6664, 6691, 6692, 6693, 6630, 6631, 6632, 3078, 6653, 6655, 6656, 6671,
  6672, 6673,
];

const boots = [3009, 3158, 3117, 3006, 3020, 3047, 3111, 4001];

const allCompletedItems = completedItems.concat(ornnItems, mythicItems, boots);

export function getCompletedItemsCount(items) {
  let count = 0;
  for (let i = 0; i < items.length; i++) {
    if (allCompletedItems.includes(items[i])) {
      count += 1;
    }
  }
  return count;
}
