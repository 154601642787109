import { window } from "global";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUrl } from "lib/hooks/urlHooks";
import { getSetAnonId } from "lib/analyticsHelper.js";

const GoogleAnalytics = (props) => {
  const apolloClient = props.client ? props.client : null;
  const location = useLocation();
  const sendUrl = useUrl({ url: location.pathname + location.search, anonId: getSetAnonId(), apolloClient: apolloClient });

  useEffect(() => {
    if (window && window.lngtd) {
      window.lngtd.resetAndRunAuction();
    }

    if (window && window.egAps && typeof window.egAps.reinstate === "function") {
      window.setTimeout(function () {
        egAps.reinstate();
      }, 1000);
    }

    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: "page_view",
        page: location.pathname + location.search,
      });
    }

    try {
      sendUrl();
    } catch (e) {
      console.log(e);
    }
  }, [location.pathname, location.search]);

  return null;
};

export default GoogleAnalytics;
