import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { MediaQuery } from "@outplayed/responsive";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import MatchCard from "components/common/MatchCard/MatchCard";
import AdPlacement from "components/common/Ads/AdPlacement";
import AdBoxWrapper from "components/common/Ads/AdBoxWrapper";
import { useMatches } from "lib/hooks/api-hooks";
import { getRoleLabel, normalizeUGGRole } from "lib/role-helpers";
import { ReactComponent as UGGLogo } from "svg/ugg-orange.svg";
import { ReactComponent as Spinner } from "svg/spinner.svg";
import { CHAMPION_MATCH_LIST } from "graphql/queries";
import { useGlobal } from "reactn";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";

const ChampionMatches = (props) => {
  const { championId, matches, mostPopularItems, matchListVariables, loading, filters } = props;
  const { getChampionName, getNormalizedChampionName } = getRiotAssetsContext();
  const location = useLocation();
  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");
  const championName = getChampionName(championId);
  const normalizedChampionName = getNormalizedChampionName(championId);
  const { recommended, recent } = matches || {};
  const [pageNumber, setPageNumber] = useState(1);
  const pageRef = useRef(1);
  const isFinalPage = useRef();
  const loadingMatchPageRef = useRef();
  const observerNode = useRef();
  const observer = useRef();
  const [getMatches, { data: matchPage, loading: matchPageLoading, error: matchPageError }] = useMatches(CHAMPION_MATCH_LIST);
  const [fullMatchList, setFullMatchList] = useState(recent || []);

  useEffect(() => {
    setPageNumber(1);
    pageRef.current = 1;
    isFinalPage.current = false;
    setFullMatchList(recent || []);
  }, [recent]);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loadingMatchPageRef.current && !isFinalPage.current) {
          loadingMatchPageRef.current = true;
          getMatches({ variables: { ...matchListVariables, pageNumber: pageRef.current } });
        }
      },
      { rootMargin: "0px", threshold: 1 },
    );

    if (observerNode.current) {
      observer.current.observe(observerNode.current);
    }

    return () => observer.current.disconnect();
  }, [matchListVariables]);

  useEffect(() => {
    if (loadingMatchPageRef.current && matchPage) {
      loadingMatchPageRef.current = false;
      pageRef.current = pageNumber + 1;
      setPageNumber(pageNumber + 1);
      const { matchList } = matchPage.getProChampionMatchList;
      if (!matchList || matchList.length === 0) {
        isFinalPage.current = true;
      } else {
        setFullMatchList(fullMatchList.concat(matchList));
      }
    }
  }, [matchPage]);

  if (loading) {
    return (
      <div className="match-list match-list_loading">
        <Spinner />
      </div>
    );
  }

  if (!recent || (recent && recent.length === 0)) {
    const uggURL = `https://u.gg/lol/champions/${normalizedChampionName}/build?role=${normalizeUGGRole(
      filters.role,
    )}&rank=overall`;
    return (
      <div className="match-list no-results">
        <img className="chibi" src="https://static.bigbrain.gg/assets/lol/art/chibis/chibi-yasuo-desktop.png" />
        <div>{`No recent ${championName} ${
          filters.role !== "all" ? getRoleLabel(filters.role) : ""
        } games by pros. Try new filters.`}</div>
        {filters.role !== "all" && (
          <div style={{ marginTop: 24 }}>
            {`Want the best ${championName} ${getRoleLabel(filters.role)} build?`}
            <UGGLogo className="ugg-logo" /> has one{" "}
            <a target="_blank" href={uggURL}>
              here
            </a>
            .
          </div>
        )}
      </div>
    );
  }

  const topCount = 3;
  // const topCount = filters.matchType === "suggested" ? 3 : 0;
  const topMatches = (recommended || []).slice(0, topCount);
  const otherMatches = fullMatchList
    .filter((match) => {
      return topMatches.find((topMatch) => topMatch.matchId === match.matchId) === undefined;
    })
    .sort((a, b) => b.matchTimestamp - a.matchTimestamp)
    .filter((m) => m.proInfo.officialName !== "");

  return (
    <React.Fragment>
      {(otherMatches.length > 0 || topMatches.length > 0) && (
        <div className="match-list all-players">
          <div className="header-wrapper">
            <div className="section-title">Live Feed</div>
            <div className="column-header header_2">Pro Player</div>

            <div className="column-header header_3">Region</div>
            <div className="column-header header_4">Matchup</div>
            <div className="column-header header_5" style={{ textAlign: "center", paddingRight: "5px" }}>
              KDA
            </div>
            <div className="column-header header_6">Rune</div>
            <div className="column-header header_7" style={{ textAlign: "center" }}></div>
            <div className="column-header header_8" style={{ textAlign: "center" }}>
              Full Item Build
            </div>
            <div className="header_9"></div>
          </div>
          {otherMatches.map((match, index) => {
            return (
              <React.Fragment key={index}>
                <MatchCard
                  index={index}
                  match={match}
                  even={(index + topMatches.length) % 2 === 0}
                  mostPopularItems={mostPopularItems}
                  world={worldsPromo && match.isWorlds === "true"}
                />
                {index > 0 && index % 10 === 0 && (
                  <AdBoxWrapper
                    styles={{ marginBottom: 6 }}
                    adStyles={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    width={300}
                    height={250}
                  >
                    <RampUnit
                      type={TagBasedAdUnits.MediumRectBTF}
                      id={`med-rect-btf-champion-${index / 10}`}
                      pathname={location.pathname}
                      search={location.search}
                      className="flex items-center justify-center w-[300px] h-[250px]"
                    />
                  </AdBoxWrapper>
                  // <div className="pw-incontent-container">
                  //   <div className="pw-incontent-mobile"></div>
                  // </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
      <div className="load-more-container" ref={observerNode}>
        {loadingMatchPageRef.current && <Spinner />}
      </div>
    </React.Fragment>
  );
};

export default ChampionMatches;
