import React from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { Item } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import { ReactComponent as IconX } from "svg/x.svg";

const ItemTimeline = (props) => {
  const { items, patch, ssr, skip, highlightItems } = props;
  const { useRiotMythicItems, useRiotCompletedItems } = getRiotAssetsContext();

  const [webpSupport] = useGlobal("webpSupport");
  const { data: mythicItems, loading: loadingMythicItems, error: errorMythicItems } = useRiotMythicItems();
  const { data: completedItems, loading: loadingCompletedItems, error: errorCompletedItems } = useRiotCompletedItems();

  const stackDuplicateItems = (visit) => {
    return visit.reduce((acc, cur, index) => {
      const dupeIndex = acc.findIndex((item) => cur.itemId === item.itemId);
      if (dupeIndex < 0) {
        // New item to list
        cur.count = 1;
        acc.push({ ...cur });
      } else {
        // Update count and latest timestamp
        if (acc[dupeIndex].sold === cur.sold) {
          acc[dupeIndex].count++;
          acc[dupeIndex].timestamp = cur.timestamp;
        } else {
          cur.count = 1;
          acc.push({ ...cur });
        }
      }

      return acc;
    }, []);
  };

  const formatItemPath = () => {
    let visit = {
      time: 0,
      items: [],
    };
    const path = [];

    items.forEach(({ itemId, timestamp, type }, index) => {
      const time = Math.trunc(timestamp / 60000);
      const itemObj = { itemId, timestamp, sold: type === 2 };

      if (visit.time === time) {
        visit.items.push(itemObj);
      } else {
        visit.items = stackDuplicateItems(visit.items);
        path.push(visit);
        visit = { time, items: [itemObj] };
      }
      if (index === items.length - 1) {
        visit.items = stackDuplicateItems(visit.items);
        path.push(visit);
      }
    });

    return path;
  };

  const buildPath = (items, patch) => {
    return items.map((itemsPerVisit, index) => {
      const time = itemsPerVisit.time;

      let visits = itemsPerVisit.items.map((item, index2) => {
        const shouldHighlight = highlightItems && !item.sold;
        return (
          <div key={`${index}_${index2}`} className="item">
            <div
              className={classnames("item-image", {
                "mythic-item": shouldHighlight && mythicItems && mythicItems.includes(item.itemId),
                "completed-item": shouldHighlight && completedItems && completedItems.includes(item.itemId),
              })}
            >
              <Item itemId={item.itemId} patch={patch} spriteSize={28} isSprite ssr={ssr} skip={skip} webp={webpSupport} />
            </div>
            {item.count > 1 && <div className="item-count">{item.count}</div>}
            {item.sold && (
              <div className="item-sold">
                <IconX />
              </div>
            )}
          </div>
        );
      });

      return (
        <div key={index} className="items-purchased-timeline">
          <div className="visit">
            <div className="items-purchased-by-minute">{visits}</div>
            <div className="timestamp">{`${time} min`}</div>
          </div>
          {index === items.length - 1 ? null : <div className="separator"></div>}
        </div>
      );
    });
  };

  const formattedItemPath = formatItemPath();
  return <div className="item-timeline">{buildPath(formattedItemPath, patch)}</div>;
};

export default ItemTimeline;
