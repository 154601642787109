export function getTierColor(tier) {
  switch (tier && tier.toLowerCase()) {
    case "iron":
      return "#51484A";
    case "bronze":
      return "#8C523A";
    case "silver":
      return "#80989D";
    case "gold":
      return "#CD8837";
    case "platinum":
      return "#25ACD6";
    case "emerald":
      return "#149C3A";
    case "diamond":
      return "#8141EB";
    case "master":
      return "#A4584E";
    case "grandmaster":
      return "#CD4545";
    case "challenger":
      return "#F4C874";
    default:
      return "";
  }
}

export function convertRomanNumeral(roman) {
  if (!roman) return "";

  switch (roman.toUpperCase()) {
    case "I":
      return 1;
    case "II":
      return 2;
    case "III":
      return 3;
    case "IV":
      return 4;
    case "V":
      return 5;
    default:
      return "";
  }
}

export function getShortRankName(rank) {
  switch ((rank || "").toLowerCase()) {
    case "unranked":
      return "-";
    case "iron":
      return "I";
    case "bronze":
      return "B";
    case "silver":
      return "S";
    case "gold":
      return "G";
    case "platinum":
      return "P";
    case "emerald":
      return "E";
    case "diamond":
      return "D";
    case "master":
      return "M";
    case "grandmaster":
      return "GM";
    case "challenger":
      return "C";
    default:
      return "—";
  }
}

export function getShortTierRank(tier, rank) {
  const normalizeTier = (tier || "").toLowerCase();
  if (normalizeTier === "master" || normalizeTier === "grandmaster" || normalizeTier === "challenger") {
    return `${getShortRankName(tier)}`;
  }

  return `${getShortRankName(tier)}${convertRomanNumeral(rank)}`;
}
