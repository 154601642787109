import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SettingsHeader from "./SettingsHeader";
import TrashcanPage from "./pages/Trashcan/TrashcanPage";
import TrashcanModal from "./pages/Trashcan/TrashcanModal";

const SettingsPage = (props) => {
  return (
    <div className="settings-page">
      <SettingsHeader />
      <Switch>
        <Route path="/settings/trashcan" component={TrashcanPage} />
        <Redirect from="/settings" to="/settings/trashcan" />
      </Switch>
    </div>
  );
};

export default SettingsPage;
