import React from "react";
import classnames from "classnames";
import FilterSelect from "../FilterSelect";
import { ReactComponent as ArrowDown } from "svg/triangle-arrow-down.svg";
const Role = (props) => {
  const { className, value, options, onFilterChange, isMobile } = props;

  const formatOptionLabel = ({ value, label, img }) => (
    <div className="role-value">
      {img}
      <ArrowDown className="arr-svg" />
    </div>
  );

  const filterClassName = classnames("filter_role", className);

  return (
    <FilterSelect
      title={"Change Role"}
      group="role"
      isMobile={isMobile}
      className={filterClassName}
      width={110}
      options={options}
      value={value}
      formatOptionLabel={formatOptionLabel}
      onChange={(selected) => {
        onFilterChange("role", selected);
      }}
    />
  );
};

export default Role;
