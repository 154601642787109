import { window } from "global";
import React, { useState } from "react";
import { useGlobal } from "reactn";
import { getLeagueSeriesUrl, getStaticPlayer2Url, getTeamUrl } from "@outplayed/riot-assets";

import Portal from "components/Portal";
import Checkbox from "components/common/Checkbox/Checkbox";
import ProHeadshot from "components/common/ProHeadshot";
import Image from "components/common/Image";
import { useProList } from "lib/hooks/json-hooks";
import { ReactComponent as TrashIcon } from "svg/trash-icon.svg";
import { ReactComponent as IconX } from "svg/x.svg";
import { ReactComponent as CogIcon } from "svg/cog-icon.svg";

import { TRASH_PLAYER_KEY, TRASH_TEAM_KEY, TRASH_LEAGUE_KEY } from "./TrashcanPage";

const TrashItem = (props) => {
  const { trashKey, image, itemLabel, itemTypeLabel, checked, onChange } = props;

  return (
    <li>
      <Checkbox onChange={onChange} checked={checked}>
        <div className="trash-modal_item">
          <div className="image-container">{image}</div>
          <div className="info">
            <div className="item_label">{itemLabel}</div>
            <div className="item_type">{itemTypeLabel}</div>
          </div>
        </div>
      </Checkbox>
    </li>
  );
};

const TrashcanModal = (props) => {
  const { proName, onCofirm, onClose } = props;
  const [localTrash, setLocalTrash] = useGlobal("localTrash");
  const [selected, setSelected] = useState(null);
  const { data, loading, error } = useProList();

  const onChange = (event, item) => {
    setSelected(event.target.checked ? item : null);
  };

  const onConfirm = () => {
    if (selected) {
      const newTrash = localTrash ? new Set([...localTrash]) : new Set();
      newTrash.add(`${selected.type}_${selected.value}`);
      window && localStorage.setItem("pbs_trash", JSON.stringify([...newTrash]));
      setLocalTrash(newTrash);
    }
    onClose();
  };

  const proData = data && data[proName || "rekkles"];

  return (
    <Portal>
      <div className="trashcan-modal">
        <div className="modal-window modal-open-animate">
          <IconX className="close" onClick={onClose} />
          <h5>Who's trash here?</h5>
          <div className="para">
            You've chosen to trash this player, team, or league. Select an option below. Trashed items will not appear in match
            feeds.
          </div>
          <div className="para">
            To view your full trashcan, visit the <br />
            <CogIcon className="cog-icon" />
            <span style={{ color: "#ffffff" }}> Settings</span> page.
          </div>
          {proData && (
            <ul>
              <TrashItem
                trashKey={`${TRASH_PLAYER_KEY}_${proData.normalized_name}`}
                image={<ProHeadshot className="player-image" src={getStaticPlayer2Url(proData.normalized_name)} />}
                itemLabel={proData.normalized_name}
                itemTypeLabel={"Pro"}
                onChange={(e) => onChange(e, { type: TRASH_PLAYER_KEY, value: proData.normalized_name })}
                checked={selected && selected.type === TRASH_PLAYER_KEY}
              />
              <TrashItem
                trashKey={`${TRASH_TEAM_KEY}_${proData.current_team}`}
                image={<Image className="team-image" src={getTeamUrl(proData.current_team)} />}
                itemLabel={proData.current_team}
                itemTypeLabel={"Team"}
                onChange={(e) => onChange(e, { type: TRASH_TEAM_KEY, value: proData.current_team })}
                checked={selected && selected.type === TRASH_TEAM_KEY}
              />
              <TrashItem
                trashKey={`${TRASH_LEAGUE_KEY}_${proData.league}`}
                image={<img className="league-image" src={getLeagueSeriesUrl(proData.league)} />}
                itemLabel={proData.league}
                itemTypeLabel={"League"}
                onChange={(e) => onChange(e, { type: TRASH_LEAGUE_KEY, value: proData.league })}
                checked={selected && selected.type === TRASH_LEAGUE_KEY}
              />
            </ul>
          )}
          <div className="confirm-btn" onClick={onConfirm}>
            <TrashIcon /> Confirm
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default TrashcanModal;
