import React from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { Item } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const ChampionItems = (props) => {
  const { useRiotCompletedItems } = getRiotAssetsContext();
  const { championName, data } = props;
  const [webpSupport] = useGlobal("webpSupport");
  const {
    data: completedItems,
    loading: loadingCompletedItems,
    error: errorCompletedItems,
  } = useRiotCompletedItems({ ssr: true });

  if (!data || (data && data.length === 0) || !completedItems) {
    return (
      <div className="champion-items">
        <div className="side-column_title">Popular Items</div>
        <div className="side-column_grid-item top-items"></div>
      </div>
    );
  }

  const filtered_data = data.filter(({ itemId, pickRate }) => completedItems.includes(itemId)).slice(0, 6);

  return (
    <div className="champion-items">
      <div className="side-column_title">Popular Items</div>
      <div className="side-column_grid-item top-items">
        {completedItems &&
          filtered_data &&
          filtered_data.map(({ itemId, pickRate }, index) => (
            <div key={itemId} className={classnames("item")}>
              <div
                className={classnames("item-image", {
                  "completed-item": completedItems && completedItems.includes(itemId),
                })}
              >
                <Item itemId={itemId} ssr webp={webpSupport} />
              </div>
              <div className="pick-rate">{Math.round(pickRate * 100)}%</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChampionItems;
