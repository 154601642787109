import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";

import { MediaQuery } from "@outplayed/responsive";

import SearchBar from "components/SearchBar/SearchBar";
import AdPlacement from "components/common/Ads/AdPlacement";
import LiveGameFeed from "./LiveGameFeed";
import { ReactComponent as Logo } from "svg/pbs-logo.svg";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";

const SEOHeaders = () => {
  const title = "Probuilds Stats | The Most Up-To-Date Pro Builds, All Regions";
  const description =
    "More pros, more regions, always up-to-date. Get your League of Legend probuild needs from the newest pro player build resource. Powered by the U.GG team";
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={"https://probuildstats.com"} />
    </Helmet>
  );
};

const LandingPage = (props) => {
  const [menuOpen] = useGlobal("mobileMenuOpen");

  const landingPageClassNames = classnames("landing-page", "content-side-padding", menuOpen && "menu-open");
  return (
    <div className={landingPageClassNames}>
      <SEOHeaders />

      <div className="landing-header-wrapper">
        <Logo className="logo" />
        <h1 className="landing_header">
          {" "}
          <span className="enlarge">PROBUILDS</span> Reimagined
        </h1>
        <h2 className="landing_sub-header-1">
          More pros, more regions, always up-to-date. Get your <br></br>League of Legend probuild needs from the newest pro{" "}
          <br></br>player build resource. Powered by the U.GG team.
        </h2>
      </div>

      <SearchBar className="landing-page_search" autoFocus />
      {/*<h2 className="landing_sub-header-2">
        <strong>Probuild Stats</strong> is the smartest pro build site yet. Don’t learn from <i>any</i> pro. <strong>Learn from the best pros.</strong> We tell you who and why.
      </h2>*/}
      <MediaQuery min="TABLET" max="DESKTOP_LARGE" isClient renderNullOnFail>
        <RampUnit className="landing-page-ads" type={TagBasedAdUnits.LeaderboardATF} id="home-leaderbaord-atf" />
      </MediaQuery>
      <LiveGameFeed />
    </div>
  );
};

export default LandingPage;
