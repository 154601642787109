import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "lib/hooks/general-hooks";
import { validateQueryParams } from "query-params/param-helpers";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import AdBoxWrapper from "components/common/Ads/AdBoxWrapper";
import MatchCard from "components/common/MatchCard/MatchCard";
import { getRoleLabel } from "lib/role-helpers";
import { useQuery, NetworkStatus } from "@apollo/client";
import { ReactComponent as UGGLogo } from "svg/ugg-orange.svg";
import { ReactComponent as Spinner } from "svg/spinner.svg";
import { PRO_MATCH_LIST } from "graphql/queries";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";

const ProMatches = (props) => {
  const { official_name, normalized_name } = props;
  const location = useLocation();
  const pageRef = useRef(1);
  const isFinalPage = useRef(false);
  const loadingMatchPageRef = useRef(true);
  const observerNode = useRef();
  const observer = useRef();

  const queryParams = useQueryParams();
  const filters = validateQueryParams(queryParams, "pro", { keepDefaultParams: true });
  const { getChampionIdByName } = getRiotAssetsContext();

  const matchListVariables = {
    proNormalizedName: normalized_name,
    ...(filters.role !== "all" && { role: filters.role }),
    ...(filters.matchup !== "all" && { opponentChampionId: parseInt(getChampionIdByName(filters.matchup)) }),
  };
  const {
    data: matchList,
    loading: loadingMatchList,
    error: errorMatchList,
    fetchMore,
    variables,
    networkStatus,
  } = useQuery(PRO_MATCH_LIST, {
    variables: matchListVariables,
    notifyOnNetworkStatusChange: true,
  });
  const { getProMatchList } = matchList || {};

  useEffect(() => {
    if (variables.page === undefined || variables.page === 1) {
      isFinalPage.current = false;
      pageRef.current = 1;
      isFinalPage.current = false;
    }
  }, [JSON.stringify(matchListVariables)]);

  useEffect(() => {
    isFinalPage.current = getProMatchList?.length % 20 !== 0;
  }, [getProMatchList]);

  useEffect(() => {
    if (!loadingMatchList) {
      loadingMatchPageRef.current = false;
    }
  }, [loadingMatchList]);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loadingMatchPageRef.current && !isFinalPage.current) {
          loadingMatchPageRef.current = true;
          const nextPage = pageRef.current + 1;
          fetchMore({
            variables: { ...matchListVariables, pageNumber: nextPage },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              pageRef.current = nextPage;
              if (!fetchMoreResult?.getProMatchList || fetchMoreResult?.getProMatchList.length === 0) {
                isFinalPage.current = true;
              }

              return {
                getProMatchList: [...(prevResult.getProMatchList || []), ...(fetchMoreResult.getProMatchList || [])],
              };
            },
          });
        }
      },
      { rootMargin: "0px", threshold: 1 },
    );

    if (observerNode.current) {
      observer.current.observe(observerNode.current);
    }

    return () => observer.current.disconnect();
  }, [matchListVariables]);

  if (networkStatus === NetworkStatus.loading) {
    return (
      <div className="match-list match-list_loading">
        <Spinner />
      </div>
    );
  }

  if (!loadingMatchList && getProMatchList?.length === 0) {
    let uggRole = "";
    if (filters.role === "top") {
      uggRole = "top-lane-tier-list";
    } else if (filters.role === "jungle") {
      uggRole = "jungle-tier-list";
    } else if (filters.role === "mid") {
      uggRole = "mid-lane-tier-list";
    } else if (filters.role === "adc") {
      uggRole = "adc-tier-list";
    } else if (filters.role === "supp") {
      uggRole = "support-tier-list";
    } else {
      uggRole = "tier-list";
    }

    const uggURL = `https://u.gg/lol/${uggRole}`;
    return (
      <div className="match-list no-results">
        <img className="chibi" src="https://static.bigbrain.gg/assets/lol/art/chibis/chibi-yasuo-desktop.png" />
        <div>{`No recent ${official_name} ${
          filters.role !== "all" ? getRoleLabel(filters.role) : ""
        } games by pros. Try new filters.`}</div>
        {filters.role !== "all" && (
          <div style={{ marginTop: 24 }}>
            {`Want the best offrole ${filters.role !== "all" ? getRoleLabel(filters.role) : ""} champs?`}
            <UGGLogo className="ugg-logo inline-flex align-baseline" /> has one{" "}
            <a target="_blank" href={uggURL}>
              here
            </a>
            .
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="match-list all-players">
      <div className="header-wrapper">
        <div className="section-title">Live Feed</div>
        <div className="column-header header_2">Pro Player</div>
        <div className="column-header header_3" style={{ textAlign: "center" }}>
          Matchups
        </div>
        <div className="column-header header_4" style={{ textAlign: "center", paddingRight: "5px" }}>
          KDA
        </div>
        <div className="column-header header_5">Rune</div>
        <div className="column-header header_6" style={{ textAlign: "center" }}></div>
        <div className="column-header header_7" style={{ textAlign: "center" }}>
          Full Item Build
        </div>
        <div className="header_8"></div>
      </div>
      {(getProMatchList || []).map((match, index) => {
        return (
          <React.Fragment key={index}>
            <MatchCard type="pro" match={match} even={index % 2 === 0} noTag />
            {index > 0 && index % 10 === 0 && (
              <AdBoxWrapper
                styles={{ marginBottom: 6 }}
                adStyles={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                width={300}
                height={250}
              >
                <RampUnit
                  type={TagBasedAdUnits.MediumRectBTF}
                  id={`med-rect-btf-champion-${index / 10}`}
                  pathname={location.pathname}
                  search={location.search}
                  className="flex items-center justify-center w-[300px] h-[250px]"
                />
              </AdBoxWrapper>
              // <div className="pw-incontent-container">
              //   <div className="pw-incontent-mobile"></div>
              // </div>
            )}
          </React.Fragment>
        );
      })}
      <div className="load-more-container" ref={observerNode}>
        {loadingMatchList && <Spinner />}
      </div>
    </div>
  );
};

export default ProMatches;
