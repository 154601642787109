import React from "react";

import allImg, { ReactComponent as RoleAll } from "svg/roles/all.svg";
import topImg, { ReactComponent as RoleTop } from "svg/roles/top.svg";
import jungleImg, { ReactComponent as RoleJungle } from "svg/roles/jungle.svg";
import midImg, { ReactComponent as RoleMiddle } from "svg/roles/mid.svg";
import botImg, { ReactComponent as RoleBottom } from "svg/roles/bot.svg";
import supportImg, { ReactComponent as RoleSupport } from "svg/roles/support.svg";

const RoleIcons = {
  ALL: RoleAll,
  TOP: RoleTop,
  JUNGLE: RoleJungle,
  MIDDLE: RoleMiddle,
  BOTTOM: RoleBottom,
  SUPPORT: RoleSupport,
};

const RoleIconURLs = {
  ALL: allImg,
  TOP: topImg,
  JUNGLE: jungleImg,
  MIDDLE: midImg,
  BOTTOM: botImg,
  SUPPORT: supportImg,
};

export const ROLES = {
  ALL: "all",
  TOP: "top",
  JUNGLE: "jungle",
  MIDDLE: "mid",
  BOTTOM: "adc", // change this to bottom
  SUPPORT: "supp",
  NONE: "none",
};

export const UGG_ROLES = {
  ALL: "all",
  TOP: "top",
  JUNGLE: "jungle",
  MIDDLE: "mid",
  BOTTOM: "adc", // change this to bottom
  SUPPORT: "support",
};

export function numToRoleMapping(num) {
  switch (Number(num)) {
    case 1:
      return ROLES.JUNGLE;
    case 2:
      return ROLES.SUPPORT;
    case 3:
      return ROLES.BOTTOM;
    case 4:
      return ROLES.TOP;
    case 5:
      return ROLES.MIDDLE;
    case 7:
      return ROLES.ALL;
    default:
      return "";
  }
}

export function normalizeRole(role) {
  switch (role) {
    case "top":
      return ROLES.TOP;
    case "middle":
    case "mid":
      return ROLES.MIDDLE;
    case "jungle":
    case "jung":
      return ROLES.JUNGLE;
    case "bottom":
    case "adc":
    case "bot":
      return ROLES.BOTTOM;
    case "support":
    case "supp":
    case "sup":
      return ROLES.SUPPORT;
    case "all":
      return ROLES.ALL;
    default:
      return role;
  }
}

export function normalizeUGGRole(role) {
  switch (role) {
    case "top":
      return UGG_ROLES.TOP;
    case "middle":
    case "mid":
      return UGG_ROLES.MIDDLE;
    case "jungle":
    case "jung":
      return UGG_ROLES.JUNGLE;
    case "bottom":
    case "adc":
    case "bot":
      return UGG_ROLES.BOTTOM;
    case "support":
    case "supp":
    case "sup":
      return UGG_ROLES.SUPPORT;
    case "all":
      return UGG_ROLES.ALL;
    default:
      return role;
  }
}

export function getRoleImg(role) {
  switch (normalizeRole(role)) {
    case ROLES.ALL:
      return <RoleIcons.ALL />;
    case ROLES.TOP:
      return <RoleIcons.TOP />;
    case ROLES.JUNGLE:
      return <RoleIcons.JUNGLE />;
    case ROLES.MIDDLE:
      return <RoleIcons.MIDDLE />;
    case ROLES.BOTTOM:
      return <RoleIcons.BOTTOM />;
    case ROLES.SUPPORT:
      return <RoleIcons.SUPPORT />;
    default:
      return null;
  }
}

export function getRoleImgURL(role) {
  switch (normalizeRole(role)) {
    case ROLES.ALL:
      return RoleIconURLs.ALL;
    case ROLES.TOP:
      return RoleIconURLs.TOP;
    case ROLES.JUNGLE:
      return RoleIconURLs.JUNGLE;
    case ROLES.MIDDLE:
      return RoleIconURLs.MIDDLE;
    case ROLES.BOTTOM:
      return RoleIconURLs.BOTTOM;
    case ROLES.SUPPORT:
      return RoleIconURLs.SUPPORT;
    default:
      return "";
  }
}

export function getRoleLabel(role) {
  switch (normalizeRole(role)) {
    case 1:
    case "jungle":
      return "Jungle";
    case 2:
    case "supp":
    case "support":
      return "Support";
    case 3:
    case "adc":
    case "bot":
    case "bottom":
      return "ADC";
    case 4:
    case "top":
      return "Top";
    case 5:
    case "mid":
    case "middle":
      return "Middle";
    case 6:
      return "";
    case 7:
    case "all":
      return "All Roles";
    default:
      return "";
  }
}

export function getRoleId(role) {
  switch (normalizeRole(role)) {
    case ROLES.ALL:
      return 7;
    case ROLES.TOP:
      return 4;
    case ROLES.JUNGLE:
      return 1;
    case ROLES.MIDDLE:
      return 5;
    case ROLES.BOTTOM:
      return 3;
    case ROLES.SUPPORT:
      return 2;
    default:
      return undefined;
  }
}
