import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { MediaQuery } from "@outplayed/responsive";
import { Rune, Item, SummonerSpell } from "@outplayed/tooltips";
import { getS13RankUrl, getRiotAssetsContext } from "@outplayed/riot-assets";

import { numberWithCommas } from "lib/general-helpers";
import { getTierColor, getShortTierRank } from "lib/rank-helpers";
import { formatProMatch } from "./match-data-parser";
import { useQuery } from "@apollo/client";
import { PRO_MATCH } from "graphql/queries";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { ReactComponent as Spinner } from "svg/spinner.svg";
import { ReactComponent as DamageIcon } from "svg/damage-icon.svg";
import { ReactComponent as MinionIcon } from "svg/minion-icon.svg";
import { ReactComponent as WardIcon } from "svg/ward-icon.svg";

function sortPlayersByRole(team) {
  const roleValue = {
    top: 1,
    jungle: 2,
    mid: 3,
    adc: 4,
    supp: 5,
  };

  const sorted = [...team];
  sorted.sort((a, b) => roleValue[a["calculatedRole"]] - roleValue[b["calculatedRole"]]);
  return sorted;
}

const TeamHeader = ({ teamId, win }) => {
  return (
    <div className="team-header">
      <div className={win ? "win" : "loss"}>{win ? "Victory" : "Defeat"}</div>&nbsp;
      <div className="team-side">({teamId === 100 ? "Blue Side" : "Red Side"})</div>
    </div>
  );
};

const MOBILE_SMALL = (props) => {
  const { team, patch, maxDamage, regionId, officialName, championId, webpSupport } = props;
  const { getChampionImg } = getRiotAssetsContext();

  const { teamId, win, participants } = team || {};

  const PlayerRow = ({ data }) => {
    const uggURL = `https://u.gg${getProfileOverviewUrl(regionId, data.riotUserName, data.riotTagLine)}`;
    const items = [...data.finalBuild].slice(0, data.finalBuild.length - 1).sort((a, b) => (!b ? -1 : 1));
    const rowClassName = classnames("player-row", {
      win: win,
      loss: !win,
      "is-current": championId === data.championId,
    });
    return (
      <div className={rowClassName}>
        <div className="row-1">
          <div className="champion-image">
            <img src={getChampionImg(data.championId, { webp: webpSupport })} />
          </div>
          <div className="player">
            <a className="summoner-name" target="_blank" href={uggURL}>
              {data.riotUserName}
            </a>
            <div className="tier">
              <img src={getS13RankUrl((data.rank && data.rank.tier) || "unranked", true)} />
              <div className="tier-text" style={{ color: getTierColor(data.rank && data.rank.tier) }}>
                {getShortTierRank(data.rank && data.rank.tier, data.rank && data.rank.rank)}
              </div>
            </div>
          </div>
          <div className="summoner-spells">
            {data.summonerSpells.map((spellId) => (
              <SummonerSpell key={spellId} spellId={spellId} webp={webpSupport} />
            ))}
          </div>
          <div className="runes">
            {[data.runes.perk0, data.runes.subStyle].map((runeId) => (
              <div className="rune-image">
                <Rune runeId={runeId} patch={patch} webp={webpSupport} />
              </div>
            ))}
          </div>
          <div className="items">
            <div className="main-items">
              {items.slice(0, 6).map((item, index) => {
                return (
                  <div key={index} className="item-image">
                    <Item itemId={item} patch={patch} spriteSize={18} isSprite webp={webpSupport} />
                  </div>
                );
              })}
            </div>
            <div className="trinket-item">
              <div className="item-image">
                <Item itemId={data.finalBuild[6]} patch={patch} spriteSize={18} isSprite webp={webpSupport} />
              </div>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="kda">
            <div className="kda-split">
              <span>{data.kills}</span>
              <span className="backslash"> / </span>
              <span style={{ color: "#ff4e50" }}>{data.deaths}</span>
              <span className="backslash"> / </span>
              <span>{data.assists}</span>
            </div>
            <span style={{ color: "#bec3e1", fontWeight: 400 }}>&nbsp; KDA</span>
          </div>
          <div className="cs">
            <MinionIcon className="minion-icon" />
            <span>{data.other.totalCS}</span>
          </div>
          <div className="damage">
            <DamageIcon className="damage-icon" />
            <span>{numberWithCommas(data.damage)}</span>
          </div>
          <div className="wards">
            <WardIcon className="ward-icon" />
            <span>{data.wardsPlaced}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="match-scoreboard">
      <div className="scoreboard_header">
        <TeamHeader teamId={teamId} win={win} />
      </div>
      <div className="scoreboard_body">
        {sortPlayersByRole(participants).map((player, index) => {
          return <PlayerRow key={index} data={player} />;
        })}
      </div>
    </div>
  );
};
const MOBILE_LARGE = (props) => {
  const { team, patch, maxDamage, regionId, officialName, championId, webpSupport } = props;
  const { getChampionImg } = getRiotAssetsContext();

  const { teamId, win, participants } = team || {};

  const PlayerRow = ({ data }) => {
    const uggURL = `https://u.gg${getProfileOverviewUrl(regionId, data.riotUserName, data.riotTagLine)}`;
    const items = [...data.finalBuild].slice(0, data.finalBuild.length - 1).sort((a, b) => (!b ? -1 : 1));
    const rowClassName = classnames("player-row", {
      win: win,
      loss: !win,
      "is-current": championId === data.championId,
    });
    return (
      <div className={rowClassName}>
        <div className="player">
          <div className="champion-image">
            <img src={getChampionImg(data.championId, { webp: webpSupport })} />
          </div>
          <div className="summoner-spells">
            {data.summonerSpells.map((spellId) => (
              <SummonerSpell key={spellId} spellId={spellId} webp={webpSupport} />
            ))}
          </div>
          <div className="runes">
            {[data.runes.perk0, data.runes.subStyle].map((runeId) => (
              <div className="rune-image">
                <Rune runeId={runeId} patch={patch} webp={webpSupport} />
              </div>
            ))}
          </div>
          <a className="summoner-name" target="_blank" href={uggURL}>
            {data.riotUserName}
          </a>
        </div>
        <div className="tier">
          <img src={getS13RankUrl((data.rank && data.rank.tier) || "unranked", true)} />
          <div className="tier-text" style={{ color: getTierColor(data.rank && data.rank.tier) }}>
            {getShortTierRank(data.rank && data.rank.tier, data.rank && data.rank.rank)}
          </div>
        </div>
        <div className="kda">
          <div className="kda-split">
            <span>{data.kills}</span>
            <span className="backslash"> / </span>
            <span style={{ color: "#ff4e50" }}>{data.deaths}</span>
            <span className="backslash"> / </span>
            <span>{data.assists}</span>
          </div>
          <div className="kda-ratio">
            <span style={{ color: data.other.kdaRatioColor }}>{data.other.kdaRatio}</span>
            <span style={{ color: "#bec3e1", fontWeight: 400 }}> KDA</span>
          </div>
        </div>
        <div className="damage">
          <DamageIcon className="damage-icon" />
          <span>{numberWithCommas(data.damage)}</span>
        </div>
        <div className="wards">
          <WardIcon className="ward-icon" />
          <span>{data.wardsPlaced}</span>
        </div>
        <div className="cs">
          <MinionIcon className="minion-icon" />
          <span>{data.other.totalCS}</span>
        </div>
        <div className="items">
          {items.map((item, index) => {
            return (
              <div key={index} className="item-image">
                <Item itemId={item} patch={patch} spriteSize={20} isSprite webp={webpSupport} />
              </div>
            );
          })}
          <div className="item-image">
            <Item itemId={data.finalBuild[6]} patch={patch} spriteSize={20} isSprite webp={webpSupport} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="match-scoreboard">
      <div className="scoreboard_header">
        <TeamHeader teamId={teamId} win={win} />
        <div>Tier</div>
        <div>KDA</div>
        <div>CS</div>
        <div>Damage</div>
        <div>Wards</div>
      </div>
      <div className="scoreboard_body">
        {sortPlayersByRole(participants).map((player, index) => {
          return <PlayerRow key={index} data={player} />;
        })}
      </div>
    </div>
  );
};
const Desktop = (props) => {
  const { team, patch, maxDamage, regionId, officialName, championId, webpSupport } = props;
  const { getChampionImg } = getRiotAssetsContext();

  const { teamId, win, participants } = team || {};

  const PlayerRow = ({ data }) => {
    const uggURL = `https://u.gg${getProfileOverviewUrl(regionId, data.riotUserName, data.riotTagLine)}`;
    const items = [...data.finalBuild].slice(0, data.finalBuild.length - 1).sort((a, b) => (!b ? -1 : 1));
    const rowClassName = classnames("player-row", {
      win: win,
      loss: !win,
      "is-current": championId === data.championId,
    });
    return (
      <div className={rowClassName}>
        <div className="player">
          <div className="champion-image">
            <img src={getChampionImg(data.championId, { webp: webpSupport })} />
          </div>
          <div className="summoner-spells">
            {data.summonerSpells.map((spellId) => (
              <SummonerSpell key={spellId} spellId={spellId} webp={webpSupport} />
            ))}
          </div>
          <div className="runes">
            {[data.runes.perk0, data.runes.subStyle].map((runeId) => (
              <div key={runeId} className="rune-image">
                <Rune runeId={runeId} patch={patch} webp={webpSupport} />
              </div>
            ))}
          </div>
          <a className="summoner-name" target="_blank" href={uggURL}>
            {data.riotUserName}
          </a>
        </div>
        <div className="tier">
          <img src={getS13RankUrl((data.rank && data.rank.tier) || "unranked", true)} />
          <div className="tier-text" style={{ color: getTierColor(data.rank && data.rank.tier) }}>
            {getShortTierRank(data.rank && data.rank.tier, data.rank && data.rank.rank)}
          </div>
        </div>
        <div className="kda">
          <div className="kda-split">
            <span>{data.kills}</span>
            <span className="backslash"> / </span>
            <span style={{ color: "#ff4e50" }}>{data.deaths}</span>
            <span className="backslash"> / </span>
            <span>{data.assists}</span>
          </div>
          <div className="kda-ratio">
            <span style={{ color: data.other.kdaRatioColor }}>{data.other.kdaRatio}</span>
            <span style={{ color: "#bec3e1", fontWeight: 400 }}> KDA</span>
          </div>
        </div>
        <div className="damage">
          <div className="damage_value">{numberWithCommas(data.damage)}</div>
          <div className="damage_bar">
            <div
              className={classnames("damage_bar-progress", win ? "win" : "loss")}
              style={{ width: `${Math.round((data.damage / maxDamage) * 100)}%` }}
            />
          </div>
        </div>
        <div className="wards">{data.wardsPlaced}</div>
        <div className="cs">{data.other.totalCS}</div>
        <div className="items">
          {items.map((item, index) => {
            return (
              <div key={index} className="item-image">
                <Item itemId={item} patch={patch} spriteSize={24} isSprite webp={webpSupport} />
              </div>
            );
          })}
          <div className="item-image">
            <Item itemId={data.finalBuild[6]} patch={patch} spriteSize={24} isSprite webp={webpSupport} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="match-scoreboard">
      <div className="scoreboard_header">
        <TeamHeader teamId={teamId} win={win} />
        <div>Tier</div>
        <div>KDA</div>
        <div>Damage</div>
        <div>Wards</div>
        <div>CS</div>
        <div>Items</div>
      </div>
      <div className="scoreboard_body">
        {sortPlayersByRole(participants).map((player, index) => {
          return <PlayerRow key={index} data={player} />;
        })}
      </div>
    </div>
  );
};

const MatchScoreboard = (props) => {
  const [webpSupport] = useGlobal("webpSupport");
  const { match } = props;
  const { version, matchId, regionId, proInfo, championId } = match;
  const { data, loading, error } = useQuery(PRO_MATCH, {
    variables: {
      matchId,
      regionId,
      version,
    },
  });

  if (loading) {
    return (
      <div className="post-game">
        <Spinner className="spinner" />
      </div>
    );
  }
  //console.log(data)
  //console.log(match)
  //console.log(error)
  if (!data || error) {
    // TODO
    return null;
  }

  const { getProMatch } = data;
  const { teams } = getProMatch;
  const { teamsPlus, maxDamage } = formatProMatch(teams);
  const blueTeam = teamsPlus[0];
  const redTeam = teamsPlus[1];

  const commonProps = {
    patch: version,
    maxDamage,
    championId,
    regionId,
    webpSupport,
    officialName: proInfo.officialName,
  };

  return (
    <div className="post-game">
      <MediaQuery at="MOBILE_SMALL" renderNullOnFail>
        <MOBILE_SMALL {...commonProps} team={blueTeam} />
        <MOBILE_SMALL {...commonProps} team={redTeam} />
      </MediaQuery>
      <MediaQuery at="MOBILE_LARGE" renderNullOnFail>
        <MOBILE_LARGE {...commonProps} team={blueTeam} />
        <MOBILE_LARGE {...commonProps} team={redTeam} />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE" renderNullOnFail>
        <Desktop {...commonProps} team={blueTeam} />
        <Desktop {...commonProps} team={redTeam} />
      </MediaQuery>
    </div>
  );
};

export default MatchScoreboard;
