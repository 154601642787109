import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { amplitudeLog } from "lib/general-helpers";
import { usePatches } from "lib/hooks/json-hooks";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { ReactComponent as UGGLogo } from "svg/ugg-logo.svg";
import { ReactComponent as LinkIcon } from "svg/link-icon.svg";

const ChampionHeader = (props) => {
  const { championId } = props;
  const { getChampionImg, getChampionName, getNormalizedChampionName, useChampionMini } = getRiotAssetsContext();

  const championName = getChampionName(championId);
  const normalizedChampionName = getNormalizedChampionName(championId);
  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const { data: patches, loading: loadingPatches, error: errorPatches } = usePatches({ ssr: true });
  const { data: championMini } = useChampionMini({ ssr: true });
  const {
    data: seoNames,
    loading: loadingSEONames,
    error: errorSEONames,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/seo-champion-names.json`, { ssr: true });

  const patch = patches && patches[0].id.replace("_", ".");
  const { name, altName, altName2 } = seoNames?.[championId] || {};
  const champ2 = altName || name || "";
  const blurb = [
    `Probuilds for ${championMini?.[championId]?.title}: the latest Solo Queue`,
    `games for ${champ2} in all regions.`,
  ];

  return (
    <div className="champion-page_header">
      <div className="main-header">
        <div className="champion-image-container">
          <div className="champion-image">
            <img src={getChampionImg(championId)} alt={`The Champion Icon for ${championName}`} />
          </div>
        </div>
        <h1 className="header-title">
          <span>{championName}</span>{" "}
          <span>
            Probuild <span>&#8211;</span> Patch
          </span>{" "}
          <span>{patch}</span>
        </h1>
        <div className="header-blurb">
          <span>{blurb[0]}&nbsp;</span>
          <span>{blurb[1]}</span>
        </div>
      </div>
      <div className="ugg-links">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://u.gg/lol/champions/${normalizedChampionName}/counter`}
          onClick={() => amplitudeLog("ugg-champion-redirect", { page: "builds", champion: championName })}
        >
          <UGGLogo className="ugg-logo" />
          <div className="link-text">{championName} Counters</div>
          <LinkIcon className="link-icon" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://u.gg/lol/champions/${normalizedChampionName}/build`}
          onClick={() => amplitudeLog("ugg-champion-redirect", { page: "counters", champion: championName })}
        >
          <UGGLogo className="ugg-logo" />
          <div className="link-text">{championName} Builds</div>
          <LinkIcon className="link-icon" />
        </a>
      </div>
    </div>
  );
};

export default ChampionHeader;
