import React from "react";

const Image = (props) => {
  const { displayNone, invisible } = props;
  let imgStyle = "";
  if (displayNone) {
    imgStyle = "display: none;";
  } else {
    imgStyle = "opacity: 0";
  }

  return (
    <div
      className="custom-image-container"
      dangerouslySetInnerHTML={{
        __html: `
      <img
        class="${props.className}"
        src="${props.src}"
        onerror="this.style='${imgStyle}';"
        alt="${props.alt || ""}"
      />
    `,
      }}
    />
  );
};

export default Image;
