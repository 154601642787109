import { DEFAULT_BREAKPOINT, BREAKPOINTS } from "constants.js";

export function getWindowBreakpoint(width) {
  if (!width) return DEFAULT_BREAKPOINT;

  for (let [breakpoint, breakpointWidth] of Object.entries(BREAKPOINTS)) {
    if (width >= breakpointWidth[0] && width <= breakpointWidth[1]) {
      return breakpoint;
    }
  }
  return DEFAULT_BREAKPOINT;
}
