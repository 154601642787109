import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { ChampionSkill } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { ReactComponent as ChevronRight } from "svg/chevron-right.svg";
const SkillRow = (props) => {
  const [webpSupport] = useGlobal("webpSupport");
  const { championId, skillOrder, skillImgs, skillKey, isPassive, slotBgColor, disableTooltip, language, ssr, skip } = props;

  return (
    <div className="skill">
      <div className="skill-img">
        <ChampionSkill
          championId={championId}
          skillSlot={skillKey}
          disableTooltip={disableTooltip}
          isPassive={isPassive}
          language={language}
          ssr={ssr}
          skip={skip}
          webp={webpSupport}
        />
      </div>
      <div className="label">{skillKey}</div>
    </div>
  );
};

const ChampionSkills = (props) => {
  const { championId, skillOrder, patch, slotBgColor, disableTooltip, language, ssr, skip } = props;
  const { useChampionIndiv, getChampionSkillImgs } = getRiotAssetsContext();

  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { patch, lang: language, ssr: true, skip });
  const skillImgs = getChampionSkillImgs(championId, { patch, optionalData: championIndiv });

  const commonProps = {
    championId,
    skillOrder,
    skillImgs,
    slotBgColor,
    disableTooltip,
    ssr: true,
    skip,
  };

  if (!skillOrder || !skillOrder["skillPriority"] || (skillOrder["skillPriority"] && skillOrder["skillPriority"].length === 0)) {
    return <div className="champion-skills"></div>;
  }

  return (
    skillOrder["skillPriority"] &&
    skillOrder["skillPriority"].length && (
      <div className="champion-skills">
        <div className="side-column_title">Skill Order</div>
        <div className="side-column_grid-item top-items">
          <SkillRow {...commonProps} skillKey={skillOrder["skillPriority"][0]} />
          <div className="arrow-align">
            <ChevronRight className="arrow" />
          </div>

          <SkillRow {...commonProps} skillKey={skillOrder["skillPriority"][1]} />
          <div className="arrow-align">
            <ChevronRight className="arrow" />
          </div>

          <SkillRow {...commonProps} skillKey={skillOrder["skillPriority"][2]} />
        </div>
      </div>
    )
  );
};

export default ChampionSkills;
