import React from "react";
import classnames from "classnames";
import FilterSelect from "../FilterSelect";
import { useTranslation } from "react-i18next";

const Region = (props) => {
  const { className, value, options, onFilterChange, isMobile } = props;
  const { t } = useTranslation();

  const formatOptionLabel = ({ value, label, img }) => {
    const imgStyle = {
      width: 18,
      height: 18,
      marginLeft: 3,
    };
    return (
      <div className="region-value">
        <img style={imgStyle} src={img} alt={label} />
        <div style={{ marginLeft: "12px" }}>{t(label)}</div>
      </div>
    );
  };

  const filterClassName = classnames("filter_region", className);

  return (
    <FilterSelect
      title={t("Change Region")}
      group="league"
      isMobile={isMobile}
      className={filterClassName}
      width={130}
      options={options}
      value={value}
      formatOptionLabel={formatOptionLabel}
      onChange={(selected) => {
        onFilterChange("region", selected);
      }}
    />
  );
};

export default Region;
