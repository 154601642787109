import React from "react";
import classnames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import { useQueryParams } from "lib/hooks/general-hooks";
import { amplitudeLog, buildQueryParams } from "lib/general-helpers";

import { MediaQuery } from "@outplayed/responsive";
import RoleSplit from "./filter-types/RoleSplit";
import Role from "./filter-types/Role";
import League from "./filter-types/League";
import Matchup from "./filter-types/Matchup";
// import MatchType from "./filter-types/MatchType";
// import MatchOutcome from "./filter-types/MatchOutcome";
import { validParams } from "query-params/valid-params";
import { validateQueryParams } from "query-params/param-helpers";
import WorldFilter from "components/Filters/filter-types/WorldFilter";
import OtpFilter from "./filter-types/OtpFilter";
import Team from "./filter-types/Team";
import { useGlobal } from "reactn";

const FilterManager = (props) => {
  const { className, page, isMobile } = props;
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");
  const onFilterChange = (filterKey, filterOption) => {
    const currentParams = {
      ...queryParams,
      [filterKey]: filterOption.value,
    };

    amplitudeLog("filter-select", { "filter-type": filterKey, "filter-value": filterOption.value });
    const validatedParams = validateQueryParams(currentParams, page);
    history.replace({
      pathname: location.pathname,
      search: buildQueryParams(validatedParams),
    });
  };

  const currentValidPageFilters = validParams[page] || {};
  const validatedParams = validateQueryParams(queryParams, page, { keepDefaultParams: true });
  const filterProps = {
    onFilterChange,
    isMobile,
  };

  const filterManagerClassName = classnames("filter-manager", className);
  return (
    <div className={filterManagerClassName}>
      <div className="filter-manager_scroll-wrapper">
        <div className="filter-manager_main">
          {validatedParams.role && (
            <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
              <RoleSplit {...filterProps} value={validatedParams.role} options={currentValidPageFilters.role} />
            </MediaQuery>
          )}
          {validatedParams.role && (
            <MediaQuery at="MOBILE_SMALL">
              <Role {...filterProps} value={validatedParams.role} options={currentValidPageFilters.role} />
            </MediaQuery>
          )}
          {/*
            validatedParams.matchType &&
            <MatchType { ...filterProps} value={validatedParams.matchType} options={currentValidPageFilters.matchType} />
          */}
          {validatedParams.league && (
            <League {...filterProps} value={validatedParams.league} options={currentValidPageFilters.league} />
          )}
          {/*
            validatedParams.matchOutcome &&
            <MatchOutcome { ...filterProps} value={validatedParams.matchOutcome} options={currentValidPageFilters.matchOutcome} />
          */}
          {validatedParams.team && <Team {...filterProps} value={validatedParams.team} isWorlds={page === "world"} />}
          {validatedParams.matchup && <Matchup {...filterProps} value={validatedParams.matchup} />}
          {worldsPromo && page === "champion" && (
            <WorldFilter {...filterProps} value={validatedParams.isWorlds} options={currentValidPageFilters.isWorlds} />
          )}
          {page === "champion" && (
            <OtpFilter {...filterProps} value={validatedParams.isOtp} options={currentValidPageFilters.isOtp} />
          )}
        </div>
      </div>
    </div>
  );
};

const FilterManagerResponsive = (props) => {
  return (
    <React.Fragment>
      <MediaQuery at="MOBILE_SMALL">
        <FilterManager {...props} isMobile />
      </MediaQuery>
      <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
        <FilterManager {...props} />
      </MediaQuery>
    </React.Fragment>
  );
};

export default FilterManagerResponsive;
