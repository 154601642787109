import React from "react";
import { useProAchievements } from "lib/hooks/json-hooks";
import { getMedalIcon, filterAchievements } from "lib/general-helpers";

const ProAchievements = (props) => {
  const { proNormalizedName, maxCount } = props;
  const { data, loading, error } = useProAchievements({ ssr: true });
  const { achieve } = (data && data[proNormalizedName]) || {};
  if (!achieve || achieve.length === 0) {
    return null;
  }

  const filteredAchievements = filterAchievements(achieve);

  return (
    <div className="pro-achievements">
      <div className="achievements-list">
        {filteredAchievements.slice(0, maxCount || 5).map((achievement, index) => {
          const { data, name, placement } = achievement;
          const medalIcon = getMedalIcon(placement);

          return (
            <div key={index} className="achievement">
              <div className="medal-icon">{medalIcon && <img src={medalIcon.src} alt={medalIcon.alt} />}</div>
              <div className="achievement-text">{name}</div>
            </div>
          );
        })}
      </div>
      <div className="source-footer">
        <span>Source: </span>
        <span>Liquipedia</span>
      </div>
    </div>
  );
};

export default ProAchievements;
