import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";

import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { Rune } from "@outplayed/tooltips";

const perkStyleDetail = (id) => {
  switch (Number(id)) {
    case 8100:
      return "Hunt and Eliminate Prey";
    case 8300:
      return "Outwit Mere Mortals";
    case 8000:
      return "Become a Legend";
    case 8400:
      return "Live Forever";
    case 8200:
      return "Unleash Destruction";
    default:
      return "";
  }
};

const RuneTree = (props) => {
  const { isPrimary, perkStyle, activePerks, patch, disableTooltip, ssr, skip } = props;

  const { getRuneJSON, useRiotRunes } = getRiotAssetsContext();

  const [webpSupport] = useGlobal("webpSupport");
  const { data, loading, error } = useRiotRunes({ patch, ssr, skip });
  const runeJSON = getRuneJSON(perkStyle, { patch, optionalData: data });

  const buildPerks = (row) => {
    if (!isPrimary && row === 0) {
      return null;
    }

    let zeroActive = true;
    const path = runeJSON.slots;
    const list = path[row].runes.map((perk) => {
      const isActive = activePerks.find((id) => perk.id === Number(id));
      if (isActive) zeroActive = false;

      const perkClassNames = classnames("perk", { perk_active: isActive });

      return (
        <div key={perk.id} className={perkClassNames}>
          <Rune runeId={perk.id} patch={patch} disableTooltip={disableTooltip} webp={webpSupport} />
        </div>
      );
    });

    const perkRowClassNames = classnames({
      "primary-perk": isPrimary,
      keystones: row === 0,
      perks: row > 0,
      "zero-active": zeroActive,
    });

    return (
      <div key={row} className={perkRowClassNames}>
        {list}
      </div>
    );
  };

  if (!runeJSON) return null;

  return (
    <div className="rune-tree-container">
      <div className="rune-tree">
        <div className="path-main">
          <div className="path-image-container">
            <Rune runeId={perkStyle} patch={patch} disableTooltip={true} webp={webpSupport} />
          </div>
          <div className="perk-style-title">
            <div>{runeJSON.name}</div>
          </div>
        </div>
        {buildPerks(0)}
        <div className={classnames("sub-perks", { "is-primary": isPrimary })}>{[1, 2, 3].map((row) => buildPerks(row))}</div>
      </div>
    </div>
  );
};

export default RuneTree;
