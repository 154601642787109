import React from "react";
import classnames from "classnames";
import FilterSelect from "../FilterSelect";

const League = (props) => {
  const { className, value, options, onFilterChange, isMobile } = props;

  const formatOptionLabel = ({ value, label, img }) => {
    const imgStyle = {
      marginLeft: "5px",
    };
    return (
      <div className="league-value">
        <img style={imgStyle} src={img} alt={label} />
        <div>{label}</div>
      </div>
    );
  };

  const filterClassName = classnames("filter_league", className);

  return (
    <FilterSelect
      placeholder="All Regions"
      title={"Change Regions"}
      group="league"
      isMobile={isMobile}
      className={filterClassName}
      width={130}
      options={options}
      value={value}
      formatOptionLabel={formatOptionLabel}
      onChange={(selected) => {
        onFilterChange("league", selected);
      }}
    />
  );
};

export default League;
