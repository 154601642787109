export const metaDefault = {
  title: "[Champion] Pro Builds | Best New Probuilds from All Regions",
  description:
    "Better data, smarter filters, more regions: [Champion] probuilds reimagined. We sort who to trust for you. See how the best [Champion] pro builds [Champion].",
};

export const meta1 = {
  title: "[Champion] Pro Builds for New Items, Patch [Patch] | Probuild Stats",
  description:
    "[Champion] probuilds reimagined: newer, smarter, and more up-to-date runes and item builds than any other site. Updated hourly. Patch [Patch].",
};

export const meta2 = {
  title: "A New and Improved Probuilds | [Champion] Items, Runes",
  description:
    "[Champion] probuilds reimagined by U.GG: newer, smarter, and more up-to-date runes and item builds than any other site. Updated hourly. Patch [Patch].",
};

export const meta3 = {
  title: "[Champion] Pro Builds | Item Builds + Runes from Faker, Jankos, and Other Pros",
  description:
    "[Champion] probuilds reimagined by U.GG: newer, smarter, and more up-to-date runes and item builds than any other site. Updated hourly. Patch [Patch].",
};

export const meta4 = {
  title: "[Champion] Pro Builds for Items, Runes in [Patch] - Probuild Stats",
  description:
    "[Champion] probuilds reimagined by U.GG: newer, smarter, and more up-to-date runes and item builds than any other site. Updated hourly. Patch [Patch].",
};

export const seoList = {
  jhin: meta1,
  ezreal: meta1,
  kayn: meta1,
  sylas: meta1,
  missfortune: meta1,
  caitlyn: meta1,
  shen: meta1,
  fiora: meta1,
  diana: meta1,
  gangplank: meta1,
  rengar: meta1,
  twistedfate: meta1,
  aatrox: meta1,
  kogmaw: meta1,
  cassiopeia: meta1,
  kassadin: meta1,
  lulu: meta1,
  jayce: meta1,
  maokai: meta1,
  fiddlesticks: meta1,
  azir: meta1,
  elise: meta1,
  sejuani: meta1,
  lissandra: meta1,
  aurelionsol: meta1,
  gnar: meta1,
  corki: meta1,
  zyra: meta1,
  karma: meta1,
  anivia: meta1,
  taric: meta1,

  ashe: meta2,
  graves: meta2,
  yasuo: meta2,
  yone: meta2,
  sett: meta2,
  irelia: meta2,
  riven: meta2,
  vladimir: meta2,
  masteryi: meta2,
  lillia: meta2,
  thresh: meta2,
  ryze: meta2,
  kindred: meta2,
  talon: meta2,
  urgot: meta2,
  galio: meta2,
  nasus: meta2,
  nautilus: meta2,
  wukong: meta2,
  veigar: meta2,
  vi: meta2,
  singed: meta2,
  yuumi: meta2,
  olaf: meta2,
  sivir: meta2,
  soraka: meta2,
  nami: meta2,
  trundle: meta2,
  sona: meta2,
  taliyah: meta2,

  seraphine: meta3,
  akali: meta3,
  vayne: meta3,
  twitch: meta3,
  hecarim: meta3,
  leesin: meta3,
  fizz: meta3,
  volibear: meta3,
  teemo: meta3,
  jinx: meta3,
  brand: meta3,
  ahri: meta3,
  nunu: meta3,
  aphelios: meta3,
  annie: meta3,
  varus: meta3,
  leblanc: meta3,
  syndra: meta3,
  sion: meta3,
  zoe: meta3,
  kennen: meta3,
  rakan: meta3,
  tryndamere: meta3,
  poppy: meta3,
  rumble: meta3,
  skarner: meta3,
  illaoi: meta3,
  ziggs: meta3,
  ivern: meta3,
  zilean: meta3,

  lucian: meta4,
  kayle: meta4,
  samira: meta4,
  senna: meta4,
  ekko: meta4,
  camille: meta4,
  zed: meta4,
  evelynn: meta4,
  leona: meta4,
  swain: meta4,
  pantheon: meta4,
  orianna: meta4,
  renekton: meta4,
  ornn: meta4,
  morgana: meta4,
  warwick: meta4,
  chogath: meta4,
  nidalee: meta4,
  rammus: meta4,
  bard: meta4,
  quinn: meta4,
  alistar: meta4,
  nocturne: meta4,
  malzahar: meta4,
  xerath: meta4,
  karthus: meta4,
  velkoz: meta4,
  kled: meta4,
  yorick: meta4,
  heimerdinger: meta4,
};
