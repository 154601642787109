import React from "react";
import Highcharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { usePatches } from "lib/hooks/json-hooks";

function checkIfChampionExists(data, championId) {
  return data && data[championId];
}

function parseTierListData(json) {
  const data = json[0];
  const champions = Object.values(data).reduce((acc, curr) => {
    return acc.concat(curr);
  }, []);
  const formattedData = {};
  champions.forEach((champion) => {
    const championId = champion[0];
    const wins = champion[2];
    const matches = champion[3];

    if (!formattedData[championId]) {
      formattedData[championId] = { wins, matches };
    } else {
      formattedData[championId].wins += wins;
      formattedData[championId].matches += matches;
    }
  });

  return formattedData;
}

const jsonFetcherOptions = {
  ssr: true,
  onCompleted: (url, json) => {
    return parseTierListData(json);
  },
};

const WinRate = (props) => {
  const { championId, championName, data } = props;
  //console.log(data)
  if (!data || (data && data.length === 0)) {
    return <div className="win-rate"></div>;
  }
  /*const {data: patches, loading: loadingPatches, error: errorPatches} = usePatches({ssr: true});
  
  const patch = patches && patches[0].id;
  if(patch){
   
    const tierlistURLs = {
      "na1": "https://stats2.u.gg/lol/1.1/champion_ranking/na1/" + patch + "/ranked_solo_5x5/master_plus/1.4.0.json",
      "euw1": "https://stats2.u.gg/lol/1.1/champion_ranking/euw1/" + patch + "/ranked_solo_5x5/master_plus/1.4.0.json",
      "kr": "https://stats2.u.gg/lol/1.1/champion_ranking/kr/" + patch +  "/ranked_solo_5x5/master_plus/1.4.0.json",
      "tr1": "https://stats2.u.gg/lol/1.1/champion_ranking/tr1/" + patch + "/ranked_solo_5x5/master_plus/1.4.0.json",
      "br1": "https://stats2.u.gg/lol/1.1/champion_ranking/br1/" + + patch + + "/ranked_solo_5x5/master_plus/1.4.0.json",
    }
    const { data: dataNA } = useJSONFetcher(tierlistURLs["na1"], jsonFetcherOptions);
    const { data: dataEUW } = useJSONFetcher(tierlistURLs["euw1"], jsonFetcherOptions);
    const { data: dataKR } = useJSONFetcher(tierlistURLs["kr"], jsonFetcherOptions);
    const { data: dataTR } = useJSONFetcher(tierlistURLs["tr1"], jsonFetcherOptions);
    const { data: dataBR } = useJSONFetcher(tierlistURLs["br1"], jsonFetcherOptions);
  
  
    const data = [
      { key: "NA", data: dataNA },
      { key: "EUW", data: dataEUW },
      { key: "KR", data: dataKR },
      { key: "TR", data: dataTR },
      { key: "BR", data: dataBR },
    ]
  
    const [totalWins, totalMatches] = data.reduce(([wins, total], curr)=>{
      if(checkIfChampionExists(curr["data"],championId)){
        return [wins+curr["data"][championId]["wins"],total+curr["data"][championId]["matches"]]
      }
      else{
        return [wins,total]
      }
    }, [0,0] );*/

  return (
    <div className="champion-win-rate">
      <div className="side-column_title">Win Rate</div>
      <div className="win-rate">{Math.round(data["percentage"] * 100) + "%"}</div>
      <div className="total-matches">{data["matches"] + " matches"}</div>
    </div>
  );
};

export default WinRate;
