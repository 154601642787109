import { window } from "global";
import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MediaQuery } from "@outplayed/responsive";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import RoleSplit from "components/Filters/filter-types/RoleSplit";

import { ROLES, getRoleId } from "lib/role-helpers";
import { ALL_ROLE_OPTIONS } from "query-params/filter-options";

const ChampionHomePage = (_props) => {
  const { getChampionImg, getNormalizedChampionName, getWorkingPatch, useChampionMini, getChampionName } = getRiotAssetsContext();
  const { data: championMini, loading: loadingChampionMini } = useChampionMini();
  const [role, setRole] = useState(ROLES.ALL);
  const latestPatch = getWorkingPatch().split(".");
  const { data: primaryRoles, loading: loadingPrimaryRoles } =
    //TODO: Make latest primary roles json file available and revert to using latest patch file
    //useJSONFetcher(`https://stats2.u.gg/lol/1.1/primary_roles/${latestPatch[0]}_${latestPatch[1]}/1.4.0.json`);
    useJSONFetcher(`https://stats2.u.gg/lol/1.1/primary_roles/11_17/1.4.0.json`);
  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  const onFilterChange = (key, option) => {
    setRole(option.value);
  };

  const sortedChampions = useMemo(() => {
    return loadingChampionMini
      ? null
      : Object.values(championMini)
          .filter((champion) => {
            if (!primaryRoles || role === ROLES.ALL || !primaryRoles[champion.key]) return true;
            return getRoleId(role) === primaryRoles[champion.key][0];
          })
          .sort((a, b) => a.name.localeCompare(b.name));
  }, [role, primaryRoles]);

  return (
    <div className="content-side-padding common-page champion-home-page">
      <Helmet>
        <title>Best New Pro Builds for Every Champion | Probuildstats</title>
        <meta
          name="description"
          content="Probuildstats has the best new pro builds for every champion and role. Get top builds from the best pros in every region."
        />
      </Helmet>
      <div className="common-page_header champion-home-page_header">
        <h1>Probuilds Champion Search</h1>
        <h5>
          <span>We have pro builds for every champion and role.&nbsp;</span>
          <span>Click a champion icon to get solo queue matches from every region.</span>
        </h5>
      </div>
      <div className="common-page_filters champion-home-page_filters">
        {/*<MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
          <Role
            value={role}
            options={ALL_ROLE_OPTIONS}
            onFilterChange={onFilterChange}
            isMobile
          />
        </MediaQuery>*/}
        <MediaQuery min="MOBILE_SMALL" max="DESKTOP_LARGE">
          <RoleSplit value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} />
        </MediaQuery>
      </div>
      <div className="common-page_content champion-home-page_content">
        {sortedChampions && (
          <div className="champion-list">
            {sortedChampions.map((champion) => {
              const normalizedChampionName = getNormalizedChampionName(champion.key);
              const url = `/champion/${normalizedChampionName}`;
              return (
                <Link key={champion.key} to={url} className="champion-link">
                  <img className="champion-image" src={getChampionImg(champion.key)} alt={getChampionName(champion.key)} />
                  <div className="champion-name">{champion.name}</div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChampionHomePage;
