import React, { useRef } from "react";

const ProHeadshot = (props) => {
  const style = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  };
  return (
    <div
      style={style}
      className="custom-pro-image-container"
      dangerouslySetInnerHTML={{
        __html: `
      <img
        class="${props.className} max-w-none"
        src="${props.src}"
        onerror="this.src='https://static.bigbrain.gg/assets/probuildstats/player_images/small_2x/placeholder.png';"
        alt="${props.alt || ""}"
      />
    `,
      }}
    />
  );
};

export default ProHeadshot;
