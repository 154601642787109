import { window } from "global";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MediaQuery } from "@outplayed/responsive";
import { getStaticPlayer2Url } from "@outplayed/riot-assets";

import AdPlacement from "components/common/Ads/AdPlacement";
import Error404Page from "components/Error404/Error404Page";
import FilterManager from "components/Filters/FilterManager";
import ProAchievements from "./ProAchievements";
import ProHeader from "./ProHeader";
import ProPageContent from "./ProPageContent";
import { ReactComponent as Spinner } from "svg/spinner.svg";
import { getRoleLabel } from "lib/role-helpers";
import { getRegionLabel } from "lib/region-helpers";
import { useProList, useProAchievements } from "lib/hooks/json-hooks";
import { filterAchievements } from "lib/general-helpers";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  // ProMatchSummary,
  GET_PRO_TAGS,
  // PRO_MATCH_LIST,
  PRO_RANK_CHAMPIONS,
  PRO_STATS_RANKING,
  PRO_RANK,
} from "graphql/queries";

const PRO_PAGE_QUERIES = gql`
  query getProPage(
    $normalizedName: String,
    $regionId: String
  ) {
    ${GET_PRO_TAGS}
    ${PRO_RANK_CHAMPIONS}
    ${PRO_STATS_RANKING}
    ${PRO_RANK}
  }
`;

const SEOHeaders = (props) => {
  const { proJSON, normalizedName } = props;
  const { official_name, region_id, main_role } = proJSON;
  const title = `${official_name} LoL Pro Builds | Newest ${getRegionLabel(region_id)} ${getRoleLabel(main_role)} Probuilds`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:image" content={getStaticPlayer2Url(normalizedName)} />
    </Helmet>
  );
};

const ProPage = (_props) => {
  const routeMatch = useRouteMatch();
  const { player } = routeMatch.params || {};
  const { data: proList, loading: loadingProList, error: errorProList } = useProList({ ssr: true });
  const {
    data: proAchievements,
    loading: loadingProAchievements,
    error: errorProAchievements,
  } = useProAchievements({ ssr: true });
  const normalizedName = player && player.toLowerCase();
  const proJSON = proList && proList[normalizedName];
  const proAdditional = proAchievements && proAchievements[normalizedName];

  const {
    data: proPageData,
    loading: loadingProPage,
    error: errorProPage,
  } = useQuery(PRO_PAGE_QUERIES, {
    variables: {
      normalizedName: normalizedName,
      regionId: proJSON && proJSON.region_id,
    },
    skip: !proJSON,
  });

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [normalizedName]);

  if (loadingProAchievements || loadingProList || loadingProPage) {
    return (
      <div className="pro-page pro-page_loading content-side-padding">
        <Spinner />
      </div>
    );
  }

  if (errorProList || errorProAchievements || errorProPage) {
    return null;
  }

  if (!proJSON) {
    return <Error404Page />;
  }

  const { getProTags, getProRank, getProRankChampions, getProStatsRanking } = proPageData;

  // const backgroundStyle = {
  //   backgroundImage: `
  //     radial-gradient(circle 841px at 34.64% -13.07%,
  //     rgba(7, 7, 32, 0.61) 0%, #070720 100%, #070720 100%),
  //     url(${getChampionSplashImg(championId)})
  //   `,
  // }
  const filteredAchievements = proAdditional && filterAchievements(proAdditional.achieve);
  return (
    <div className="pro-page_bg">
      <SEOHeaders proJSON={proJSON} normalizedName={normalizedName} />
      <div className="pro-page content-side-padding">
        <div className="pro-page_header-container">
          <ProHeader proJSON={proJSON} proAdditional={proAdditional} proTags={getProTags} rankChampions={getProRankChampions} />
          <MediaQuery>
            <div className="pro-page_header_achievements">
              {filteredAchievements && filteredAchievements.length > 0 && (
                <React.Fragment>
                  <div className="title">Recent Achievements</div>
                  <ProAchievements proNormalizedName={normalizedName} />
                </React.Fragment>
              )}
            </div>
          </MediaQuery>
          <MediaQuery>
            <div className="pro-page_filters">
              <FilterManager page="pro" />
            </div>
          </MediaQuery>
        </div>
        <ProPageContent proJSON={proJSON} achievements={filteredAchievements} />
      </div>
    </div>
  );
};

export default ProPage;
