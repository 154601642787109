import React from "react";

import { ReactComponent as UGGLogo } from "svg/ugg-orange.svg";
import { ReactComponent as DiscordIcon } from "svg/social/discord.svg";
import { ReactComponent as FacebookIcon } from "svg/social/facebook.svg";
import { ReactComponent as InstagramIcon } from "svg/social/instagram.svg";
import { ReactComponent as TwitterIcon } from "svg/social/twitter.svg";
import { ReactComponent as RedditIcon } from "svg/social/reddit.svg";

const socialMedia = [
  { link: "https://www.facebook.com/UdotGG", SocialImg: FacebookIcon, alt: "Facebook" },
  { link: "https://www.twitter.com/UdotGG", SocialImg: TwitterIcon, alt: "Twitter" },
  { link: "https://www.instagram.com/UdotGG", SocialImg: InstagramIcon, alt: "Instagram" },
  { link: "https://www.reddit.com/r/UGITGUD", SocialImg: RedditIcon, alt: "Reddit" },
  { link: "https://discord.gg/hR7vrQ6", SocialImg: DiscordIcon, alt: "Discord" },
];

const Footer = (props) => {
  return (
    <div id="footer">
      {/*<img className="paint-bg" src="https://static.bigbrain.gg/assets/probuildstats/pages/paint-bg.png" />*/}
      <div className="footer-bg">
        <div className="footer-container content-side-padding">
          <div className="footer_section disclaimers">
            <div className="footer_header whitespace-nowrap">PROBUILD STATS</div>
            <span className="copyright">© 2024 Outplayed Inc.</span>
            <span className="disclaimer">
              Probuild Stats isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone
              officially involved in producing or managing League of Legends.{" "}
            </span>
          </div>
          <div className="footer_section network">
            <div className="footer_header">
              <UGGLogo className="ugg-logo inline-block align-baseline" /> Network
            </div>
            <div className="links">
              <a target="_blank" rel="noreferrer" href="https://u.gg">
                U.GG
              </a>
              <a target="_blank" rel="noreferrer" href="https://probuildstats.com">
                ProbuildStats.com
              </a>
              <a target="_blank" rel="noreferrer" href="https://u.gg/privacy-policy">
                Privacy Policy
              </a>
              <a href="mailto:info@u.gg">Contact Us</a>
            </div>
          </div>
          <div className="footer_section connect-with-us">
            <div className="footer_header whitespace-nowrap">Connect With Us</div>
            <div className="social-links">
              {socialMedia.map(({ link, SocialImg, alt }) => {
                return (
                  <a key={link} target="_blank" rel="noreferrer" href={link} className="social-link">
                    <SocialImg />
                    <span>{alt}</span>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
