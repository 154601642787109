import React from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { Item } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const ChampionBoots = (props) => {
  const { useRiotMythicItems, useRiotCompletedItems } = getRiotAssetsContext();
  const { championName, data } = props;
  const [webpSupport] = useGlobal("webpSupport");
  const { data: mythicItems, loading: loadingMythicItems, error: errorMythicItems } = useRiotMythicItems({ ssr: true });
  const {
    data: completedItems,
    loading: loadingCompletedItems,
    error: errorCompletedItems,
  } = useRiotCompletedItems({ ssr: true });

  if (!data || (data && data.length === 0) || !mythicItems) {
    return (
      <div className="champion-skills">
        <div className="side-column_title">Popular Boots</div>
        <div className="side-column_grid-item top-items"></div>
      </div>
    );
  }

  const filtered_data = data.filter(({ itemId, pickRate }) => !mythicItems.includes(itemId)).slice(0, 3);

  return (
    <div className="champion-items champion-boots">
      <div className="side-column_title">Boots</div>
      <div className="side-column_grid-item top-items">
        {completedItems &&
          filtered_data &&
          filtered_data.map(({ itemId, pickRate }, index) => (
            <div
              key={itemId}
              className={classnames("item", {
                "do-not-display": mythicItems && mythicItems.includes(itemId),
              })}
            >
              <div
                className={classnames("item-image", {
                  "mythic-item": mythicItems && mythicItems.includes(itemId),
                  "completed-item": completedItems && completedItems.includes(itemId),
                })}
              >
                <Item itemId={itemId} ssr webp={webpSupport} />
              </div>
              <div className="pick-rate">{Math.round(pickRate * 100)}%</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChampionBoots;
