import gql from "graphql-tag";

export const RankScore = `
  lastUpdatedAt
  losses
  lp
  promoProgress
  queueType
  rank
  role
  seasonId
  tier
  wins
`;

export const ProMatchRunes = `
  perk0
  perk1
  perk2
  perk3
  perk4
  perk5
  primaryStyle
  subStyle
`;

export const ProInfo = `
  league
  mainRole
  currentTeam
  officialName
  tags
  region
`;

export const ProMatchSummary = `
  calculatedRole
  championId
  cs
  completedItems
  currentTeam
  finalBuild
  gold
  itemPath {
    itemId
    timestamp
    type
  }
  jungleCs
  killParticipation
  matchDuration
  matchId
  isWorlds
  matchTimestamp
  normalizedName
  proInfo {
    ${ProInfo}
  }
  opponentChampionId
  proLeague
  regionId
  runes {
    ${ProMatchRunes}
  }
  riotUserName
  riotTagLine
  statShards
  seasonId
  skillEvolveOrders
  skillOrders
  summonerSpells
  teamId
  totalAssists
  totalDeaths
  totalKills
  version
  win
`;

export const GET_PRO_TAGS = `
  getProTags(
    normalizedName: $normalizedName,
    regionId: $regionId
  )
`;

export const PROBUILD_URL = gql`
  query probuildUrl($url: String!, $anonId: String) {
    probuildUrl(url: $url, anonId: $anonId) {
      success
    }
  }
`;

export const GET_POPULAR_PROS = gql`
  query {
    getPopularPros {
      bestChampions
      normalizedName
      proInfo {
        ${ProInfo}
      }
      mainRole
    }
  }
`;

export const TOP_PROS_FOR_CHAMPION = `
  getTopProsForChampion(championId: $championId) {
    normalizedName
    proInfo {
      ${ProInfo}
    }
    score
    totalWins
    totalGames
    winRate
  }
`;
export const CHAMPION_WIN_RATE = `
  getProChampionWinRate(
    championId: $championId,
    role: $role,
    leagueId: $leagueId,
    isWorlds: $isWorlds,
    isOtp: $isOtp,
    opponentChampionId:$opponentChampionId
  ) {
    matches
    percentage
  }
`;

export const CHAMPION_SPELLS = `
  getProChampionSpellPercentages(
    championId: $championId,
    role: $role,
    leagueId: $leagueId,
    isWorlds: $isWorlds,
    isOtp: $isOtp,
    opponentChampionId:$opponentChampionId
  ) {
    spell
    percentage
  }
`;
export const CHAMPION_SKILL_PRIORITY = `
 getProChampionSkillPriority(
    championId: $championId,
    role: $role,
    leagueId: $leagueId,
    opponentChampionId:$opponentChampionId
  ) {
    skillPriority
  }
`;

export const CHAMPION_ROLE_PERCENTAGES = `
  getProChampionRolePercentages(
    championId: $championId,
    leagueId: $leagueId,
    isWorlds: $isWorlds,
    isOtp: $isOtp
  ) {
    adc,
    jungle,
    mid,
    supp,
    top
  }
`;

export const CHAMPION_RUNE_PERCENTAGES = `
  getProChampionRunePercentages(
    championId: $championId,
    role: $role,
    leagueId: $leagueId,
    isWorlds: $isWorlds,
    isOtp: $isOtp,
    opponentChampionId:$opponentChampionId
  ) {
    count
    percentage
    perk0
    subStyle
  }
`;

export const PRO_RANK_CHAMPIONS = `
  getProRankChampions(
    normalizedName: $normalizedName,
    regionId: $regionId
  ) {
    basicChampionPerformances {
      assists
      championId
      cs
      damage
      damageTaken
      deaths
      doubleKills
      gold
      kills
      maxDeaths
      maxKills
      pentaKills
      quadraKills
      totalMatches
      tripleKills
      wins
    }
  }
`;

export const PRO_STATS_RANKING = `
  getProStatsRanking(normalizedName: $normalizedName) {
    csPerMinRanking
    goldPerMinRanking
    jungleCsPerMinRanking
    kdaRanking
    killParticipationRanking
  }
`;

export const PRO_RANK = `
  getProRank(
    normalizedName: $normalizedName,
    regionId: $regionId
  ) {
    rankScores {
      ${RankScore}
    }
  }
`;

export const CHAMPION_MATCH_LIST = gql`
  query ChampionMatchList(
    $championId: Int,
    $league: String,
    $recommendedFirst: Boolean,
    $role: String,
    $victoryOnly: Boolean,
    $opponentChampionId: Int,
    $leagueBlocklist: [String],
    $teamBlocklist: [String],
    $proBlocklist: [String],
    $pageNumber: Int,
    $isWorlds: Boolean,
    $isOtp: Boolean,
    $proTeam: String
  ) {
    getProChampionMatchList(
      championId: $championId,
      league: $league,
      recommendedFirst: $recommendedFirst,
      role: $role,
      victoryOnly: $victoryOnly,
      opponentChampionId: $opponentChampionId,
      leagueBlocklist: $leagueBlocklist,
      teamBlocklist: $teamBlocklist,
      proBlocklist: $proBlocklist,
      pageNumber: $pageNumber,
      isWorlds: $isWorlds,
      isOtp: $isOtp,
      proTeam: $proTeam
    ) {
      matchList {
        ${ProMatchSummary}
      }
      mostPopularItems {
        itemId
        pickRate
      }
      mostPopularBoots {
        itemId
        pickRate
      }
    }
  }
`;

export const PRO_MATCH_LIST = gql`
  query ProMatchList(
    $proNormalizedName: String,
    $recommendedFirst: Boolean,
    $role: String,
    $victoryOnly: Boolean,
    $pageNumber: Int
  ) {
    getProMatchList(
      proNormalizedName: $proNormalizedName,
      recommendedFirst: $recommendedFirst,
      role: $role,
      victoryOnly: $victoryOnly,
      pageNumber: $pageNumber
    ) {
      ${ProMatchSummary}
    }
  }
`;

export const PRO_MATCH = gql`
  query ProMatch(
    $matchId: Int!,
    $regionId: String!,
    $version: String!
  ) {
    getProMatch(
      matchId: $matchId,
      regionId: $regionId,
      version: $version
    ) {
      teams {
        teamId
        win
        participants {
          assists
          calculatedRole
          championId
          cs
          damage
          deaths
          finalBuild
          jungleCs
          kills
          officialName
          rank {
            ${RankScore}
          }
          runes {
            ${ProMatchRunes}
          }
          statShards
          riotUserName
          riotTagLine
          summonerSpells
          wardsPlaced
        }
      }
    }
  }
`;
export const GET_LATEST_WORLD_MATCHES = gql`
  query getLatestWorldsMatchList(
    $isWorlds: Boolean,
    $league: String,
    $role: String) {
    getLatestWorldsMatchList(
      isWorlds: $isWorlds,
      league: $league,
      role: $role) {
      ${ProMatchSummary}
    }
  }
`;

export const TOP_PRO_CHAMPION_PICKS = gql`
  query TopProChampionPicks($version: String) {
    getTopProChampionPicks(version: $version) {
      championId
      pickRate
      role
      winRate
    }
  }
`;
