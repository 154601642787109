import React, { useEffect, useMemo, useRef } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import { getRoleImg } from "lib/role-helpers";
import { getTierColor } from "lib/general-helpers";

const TopPicksTable = (props) => {
  const { topPicks } = props;
  const { getChampionName, getNormalizedChampionName, getChampionImg } = getRiotAssetsContext();

  const tableRef = useRef();
  const [windowBreakpoint] = useGlobal("responsive");
  const { data, min, max } = topPicks;

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
    }
  }, [topPicks]);

  const tableData = useMemo(() => {
    return data;
  }, [topPicks]);

  const columns = useMemo(
    () => [
      {
        id: "rank",
        maxWidth: windowBreakpoint === "TABLET" ? 80 : windowBreakpoint === "MOBILE_LARGE" ? 50 : 30,
        Cell: ({ index }) => <span>{index + 1}</span>,
      },
      {
        Header: "Champion",
        accessor: "championId",
        className: "champion",
        disableSortBy: true,
        Cell: ({ cell, state }) => {
          const [{ id: sortId }] = state.sortBy;
          const { championId, pickRate, role, winRate } = cell.row.original;
          const barColor = sortId === "pickRate" ? "#F8B218" : getTierColor(winRate * 100);
          const barWidth = sortId === "pickRate" ? pickRate / max.pickRate : winRate / max.winRate;
          const normalizedName = getNormalizedChampionName(championId);
          const url = `/champion/${normalizedName}`;

          return (
            <Link to={url} className="champion-column">
              <div className="champion-image-container">
                <img src={getChampionImg(championId)} />
              </div>
              <div className="champion-info">
                <div className="champion-name">
                  {getRoleImg(role)}
                  <span>{getChampionName(championId)}</span>
                </div>
                <div className="rate-bar">
                  <div className="bar-percent" style={{ backgroundColor: barColor, width: `${barWidth * 100}%` }} />
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        Header: () => <div className="header-container">Pick Rate</div>,
        headerClassName: "pickrate-header",
        id: "pickRate",
        accessor: "pickRate",
        maxWidth: windowBreakpoint === "TABLET" ? 150 : windowBreakpoint === "MOBILE_LARGE" ? 120 : 72,
        sortDescFirst: true,
        sortType: (a, b) => a.values.pickRate - b.values.pickRate,
        Cell: ({ value }) => <span>{Math.round(value * 1000) / 10}%</span>,
      },
      {
        Header: () => <div className="header-container">Win Rate</div>,
        headerClassName: "winrate-header",
        id: "winRate",
        accessor: "winRate",
        maxWidth: windowBreakpoint === "TABLET" ? 150 : windowBreakpoint === "MOBILE_LARGE" ? 120 : 72,
        sortDescFirst: true,
        sortType: (a, b) => a.values.winRate - b.values.winRate,
        Cell: ({ value }) => <span>{Math.round(value * 1000) / 10}%</span>,
      },
    ],
    [windowBreakpoint],
  );

  const { state, getTableProps, getTableBodyProps, headerGroups, rows, preSortedRows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [{ id: "pickRate", desc: true }],
      },
      disableSortRemove: true,
    },
    useSortBy,
  );

  const columnStyle = (column) => ({
    minWidth: column.totalMinWidth,
    maxWidth: column.totalMaxWidth,
    flex: `1 1 100%`,
  });

  return (
    <div className="top-picks-table">
      <div ref={tableRef} {...getTableProps({ className: "rt_table" })}>
        <div className="rt_header">
          {headerGroups.map((headerGroup, index) => (
            <div key={index} {...headerGroup.getHeaderGroupProps({ className: "rt_header__row" })}>
              {headerGroup.headers.map((column, index) => {
                const thClassNames = classnames([
                  "rt_header__cell",
                  column.headerClassName,
                  column.isSorted ? "is-sorted" : "",
                  column.isSorted && !column.isSortedDesc ? "sort-asc" : "",
                  column.isSorted && column.isSortedDesc ? "sort-desc" : "",
                ]);

                return (
                  <div
                    key={index}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ className: thClassNames, style: columnStyle(column) }),
                    )}
                  >
                    {column.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps({ className: "rt_body" })}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div key={i} {...row.getRowProps({ className: "rt_body__row" })}>
                {row.cells.map((cell, index) => {
                  const tdClassNames = classnames("rt_body__cell", cell.column.className, {
                    "is-sorted": cell.column.isSorted,
                  });

                  return (
                    <div key={index} {...cell.getCellProps({ className: tdClassNames, style: columnStyle(cell.column) })}>
                      {cell.render("Cell", { index: i })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TopPicksTable;
