import React from "react";
import { NavLink } from "react-router-dom";

const PageNav = (props) => {
  const { pages } = props;
  // pages.label
  // pages.path

  return (
    <div className="page-nav">
      {pages.map((page, index) => {
        return (
          <NavLink key={index} className="page-nav_link" to={page.path}>
            {page.label}
          </NavLink>
        );
      })}
    </div>
  );
};

export default PageNav;
