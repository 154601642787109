import { window } from "global";
import fetch from "node-fetch";
import React, { useState } from "react";
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/link-context";
// import { graphqlURI } from "api/devConstants";

export function initApolloClient({ authToken = "", preloadedState = {}, ssrMode = false } = {}) {
  let graphQLUri = process.env.GRAPHQL_URI || "https://u.gg/api";
  if (window) {
    const regionalUrls = [
      "na.probuildstats.com",
      "eu.probuildstats.com",
      "kr.probuildstats.com",
      "sa.probuildstats.com",
      "oce.probuildstats.com",
    ];
    if (regionalUrls.includes(window.location.hostname)) {
      graphQLUri = `https://${window.location.hostname}/api`;
    }
  }
  const httpLink = createHttpLink({ uri: graphQLUri, fetch });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    };
  });

  let cache = new InMemoryCache();
  if (preloadedState && Object.keys(preloadedState).length > 0) {
    cache = new InMemoryCache().restore(preloadedState);
  }

  const apolloClient = new ApolloClient({
    ssrMode,
    cache,
    link: authLink.concat(httpLink),
  });

  return apolloClient;
}

const ApolloWrapper = (props) => {
  const { client: passedClient } = props;
  const [client, setClient] = useState(passedClient || initApolloClient());

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ApolloWrapper;
