import { CSSProperties } from "react";
import classNames from "classnames";
import { useGlobal } from "reactn";

interface AdBoxWrapperProps {
  className?: string;
  styles?: CSSProperties;
  adStyles?: CSSProperties;
  children: React.ReactNode;
  minAdWidth?: number;
  minAdHeight?: number;
  width?: number;
  height?: number;
}

const AdBoxWrapper = (props: AdBoxWrapperProps) => {
  const [adblock] = useGlobal("adblock");
  const [isDetectingAdblock] = useGlobal("isDetectingAdblock");
  const { className, styles = {}, adStyles = {}, children, minAdWidth = 0, minAdHeight = 0, width, height } = props;

  if (adblock || isDetectingAdblock) {
    return null;
  }

  const adContainerStyle: CSSProperties = {
    position: "relative",
    minWidth: minAdWidth,
    minHeight: minAdHeight,
    width: width !== undefined ? width : "auto",
    height: height !== undefined ? height : "auto",
    ...adStyles,
  };

  return (
    <div className={classNames("ad-box-wrapper", className)} style={styles}>
      <span className="ad-box_label">Advertisement</span>
      <div style={adContainerStyle}>{children}</div>
    </div>
  );
};

export default AdBoxWrapper;
