import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { useProList } from "lib/hooks/json-hooks";
import { getStaticPlayer2Url, getTeamUrl, getRiotAssetsContext } from "@outplayed/riot-assets";

import Autosuggest from "react-autosuggest";
import { fuzzySearch } from "lib/search-helpers";
import ProHeadshot from "components/common/ProHeadshot";
import Image from "components/common/Image";
import { ReactComponent as SearchIcon } from "svg/search-icon.svg";

function formatChampionsData(data) {
  return Object.values(data).map((champion) => {
    let key = champion.id.toLowerCase();
    if (key === "monkeyking") {
      key = "wukong";
    }
    return {
      type: "champion",
      id: champion.key,
      key,
      displayName: champion.name,
      url: `/champion/${champion.id.toLowerCase()}`,
    };
  });
}

function formatProsData(data) {
  const prosMap = {};
  data.forEach((pro) => {
    if (!prosMap[pro.official_name]) {
      prosMap[pro.official_name] = {
        type: "pro",
        key: pro.normalized_name,
        displayName: pro.official_name,
        team: pro.current_team,
        url: `/pro/${pro.normalized_name}`,
      };
    }
  });

  return Object.values(prosMap);
}

function getSuggestionValue(suggestion) {
  return suggestion.displayName;
}

function getSectionSuggestions(section) {
  return section.pages;
}

function renderSectionTitle(section) {
  return <div className="section_title">{section.title}</div>;
}

function renderSuggestion(suggestion, { webp }, getChampionImg) {
  if (suggestion.type === "champion") {
    return (
      <div className="suggestion suggestion_champion">
        <div className="suggestion__icon champion-image">
          <img src={getChampionImg(suggestion.id, { webp })} />
        </div>
        <div className="suggestion__text">{suggestion.displayName}</div>
      </div>
    );
  } else if (suggestion.type === "pro") {
    return (
      <div className="suggestion suggestion_pro">
        <div className="suggestion__icon headshot">
          <ProHeadshot className="headshot-img" src={getStaticPlayer2Url(suggestion.key)} />
        </div>
        <div className="team-logo">
          <Image src={getTeamUrl(suggestion.team)} invisible />
        </div>
        <div className="suggestion__text">{suggestion.displayName}</div>
      </div>
    );
  } else {
    return null;
  }
}

const MAX_CHAMPIONS = 5;
const MAX_PROS = 5;

const SearchBar = (props) => {
  const { className, isMasthead, autoFocus } = props;
  const { useChampionMini, getChampionImg } = getRiotAssetsContext();

  const history = useHistory();
  const [webpSupport] = useGlobal("webpSupport");
  const [windowBreakpoint] = useGlobal("responsive");
  const [init, setInit] = useState(false);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { data: prosData, loading: prosLoading, error: prosError } = useProList();
  const { data: championsData, loading: championsLoading, error: championsError } = useChampionMini();

  useEffect(() => {
    setInit(true);
  }, []);

  const championPages = useMemo(() => {
    return !championsData ? [] : formatChampionsData(championsData);
  }, [championsData]);

  const proPages = useMemo(() => {
    return !prosData ? [] : formatProsData(Object.values(prosData));
  }, [prosData]);

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    const fuzzyChampions = fuzzySearch(value.toLowerCase(), championPages);
    const fuzzyPros = fuzzySearch(value.toLowerCase(), proPages);
    const championSuggestions = {
      title: "Champions",
      pages: fuzzyChampions.slice(0, MAX_CHAMPIONS).map((el) => el.item),
    };
    const proSuggestions = {
      title: "Pros",
      pages: fuzzyPros.slice(0, MAX_PROS).map((el) => el.item),
    };

    const suggestions = [championSuggestions, proSuggestions].filter((section) => section.pages.length > 0);

    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    history.push(suggestion.url);
  };

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
  };

  const inputProps = {
    placeholder: windowBreakpoint === "MOBILE_SMALL" ? "Search" : "Search for a Champion or Pro Player",
    value,
    onChange,
    autoFocus,
  };

  const searchBarClassNames = classnames(
    "search-bar_base",
    "search-bar_MOBILE_SMALL",
    {
      "search-bar_placeholder": !init,
      "search-bar_masthead": isMasthead,
    },
    className,
  );

  if (!init) {
    <div className={searchBarClassNames}></div>;
  }

  return (
    <div className={searchBarClassNames}>
      <SearchIcon className="search-icon" />
      {init && (
        <Autosuggest
          multiSection
          highlightFirstSuggestion
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(suggestion) => renderSuggestion(suggestion, { webp: webpSupport }, getChampionImg)}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />
      )}
    </div>
  );
};

export default SearchBar;
