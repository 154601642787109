import React, { useMemo } from "react";
import classnames from "classnames";
import { getTeamUrl } from "@outplayed/riot-assets";

import FilterSelect from "../FilterSelect";
import { useProList } from "lib/hooks/json-hooks";

const worlds_2021 = [
  // China
  "FunPlus Phoenix",
  "Edward Gaming",
  "Royal Never Give Up",
  "LNG Esports",
  // Korea
  "DWG KIA",
  "Gen.G",
  "T1",
  "Hanwha Life Esports",
  // EU
  "MAD Lions",
  "Fnatic",
  "Rogue",
  // NA
  "100 Thieves",
  "Team Liquid",
  "Cloud9",
  // Wild Cards
  "PSG Talon",
  "Beyond Gaming",
  "Unicorns of Love",
  "INFINITY",
  "Galatasaray Espor",
  "RED Kalunga",
  "DetonatioN FocusMe",
  "Peace",
];

const Team = (props) => {
  const { className, value, onFilterChange, isWorlds, isMobile } = props;
  const { data: proList, loading: loadingProList } = useProList();
  //console.log(onFilterChange)
  const options = useMemo(() => {
    if (!proList) return [];

    const teams = new Set();
    Object.values(proList).forEach((pro) => teams.add(pro.current_team));

    const teamOptions = [...teams]
      .map((team) => ({ value: team, label: team, img: getTeamUrl(team) }))
      .sort((a, b) => a.label.localeCompare(b.label));

    teamOptions.unshift({ value: "all", label: "All Teams" });

    return teamOptions;
  }, [proList]);

  const world_options = worlds_2021
    .map((team) => ({ value: team, label: team, img: getTeamUrl(team) }))
    .sort((a, b) => a.label.localeCompare(b.label));
  world_options.unshift({ value: "all", label: "All Teams" });

  const formatOptionLabel = ({ value, label, img }) => {
    return (
      <div className="team-value">
        {img && <img src={img} alt={label} />}
        <div style={{ marginLeft: img ? "12px" : "0px" }}>{label}</div>
      </div>
    );
  };

  const filterClassName = classnames("filter_team", className);

  return (
    <FilterSelect
      placeholder={""}
      title={"Change Team"}
      group="team"
      isMobile={isMobile}
      className={filterClassName}
      width={200}
      options={isWorlds ? world_options : options}
      value={value}
      formatOptionLabel={formatOptionLabel}
      onChange={(selected) => {
        onFilterChange("team", selected);
      }}
    />
  );
};

export default Team;
