import { validParams, globalExemptions } from "./valid-params";

export function validateQueryParams(queryParams, page, { keepDefaultParams = false } = {}) {
  let validParameters = validParams; // default champion stats params

  let validPageParams = validParameters[page] || {};
  let defaultParams = validPageParams.default || {};
  let cleanParams = { ...queryParams };

  // Delete any undefined keys
  Object.keys(cleanParams).forEach((key) => {
    if (!cleanParams[key]) delete cleanParams[key];
  });
  let filters = Object.assign({}, defaultParams, cleanParams);

  let validatedParams = keepDefaultParams ? { ...filters } : { ...cleanParams };
  const currentParams = Object.keys(validatedParams);
  currentParams.forEach((key) => {
    const paramValue = validatedParams[key];
    // Remove if default param value
    if (!keepDefaultParams && defaultParams[key] === paramValue) {
      delete validatedParams[key];
    } else {
      // Check any exemptions
      const checkGlobalExemption = globalExemptions.includes(key);
      const checkPageExemption = validPageParams["exemptions"] ? validPageParams["exemptions"].includes(key) : false;
      if (checkGlobalExemption === false && checkPageExemption === false) {
        const isValidValue = validPageParams[key].find((option) => {
          return option.value === paramValue;
        });
        // Remove if not valid param value
        if (isValidValue === undefined) {
          if (keepDefaultParams && defaultParams[key]) {
            validatedParams[key] = defaultParams[key];
          } else {
            delete validatedParams[key];
          }
        }
      }
    }
  });
  return validatedParams;
}
