import { PROBUILD_URL } from "graphql/queries";

export const useUrl = (vars) => {
  const client = vars.apolloClient ? vars.apolloClient : null;
  delete vars["apolloClient"];

  if (!client) {
    return () => {};
  }

  return () => {
    client
      .query({
        query: PROBUILD_URL,
        variables: vars,
      })
      .then((response) => {
        const { data, errors } = response;
        if (errors) {
          console.log(errors);
        } else {
          //   console.log("DATA: ", data);
        }
      });
  };
};
