import React, { useState, useEffect, useRef } from "react";
import Portal from "components/Portal";
import ReactSVG from "react-svg";

export const RadioButton = (props) => {
  const { group, option, isChecked, onChange } = props;

  return (
    <div className="filter-modal_radio-buttons">
      <label>
        {option.label}
        <input type="radio" value={option.value} checked={isChecked} name={group} onChange={onChange.bind(this, option)} />
        <span className="radio-button"></span>
      </label>
    </div>
  );
};

const FilterModal = (props) => {
  const { button, title, group, contentClassNames, curValue, options, onApply, onCancel } = props;

  const [showModal, setShowModal] = useState(false);
  const [scrollableBelow, setScrollableBelow] = useState(false);
  const [optionToApply, setOptionToApply] = useState(curValue);
  const content = useRef();

  useEffect(() => {
    if (!optionToApply) {
      setOptionToApply(curValue);
    }
  }, [curValue]);

  const toggleModal = (e) => {
    setShowModal(!showModal);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onCancelHook = () => {
    onCancel && onCancel();
    closeModal();
  };

  const onApplyHook = () => {
    if (onApply) {
      options ? onApply(optionToApply) : onApply();
    }
    closeModal();
  };

  const listOptions = (options, curValue) => {
    return (
      <div className="filter-modal_options">
        {options.map((option) => {
          const isChecked = curValue && option.value === optionToApply.value;

          return (
            <RadioButton key={option.value} group={group} option={option} isChecked={isChecked} onChange={setOptionToApply} />
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {button && (
        <div className="select_modal">
          <div className={`select_modal__toggle`} onClick={toggleModal}>
            {button}
          </div>
        </div>
      )}
      {showModal && (
        <Portal>
          <div className="filter-modal">
            <div className="filter-modal_header">
              <div>{title}</div>
              <div className="filter-modal_close" onClick={onCancelHook}></div>
            </div>
            <div ref={content} className={`filter-modal_content ${contentClassNames}`}>
              {options ? listOptions(options, curValue) : props.children}
            </div>
            <div className={`filter-modal_buttons ${scrollableBelow ? "fade-content" : ""}`}>
              <div onClick={onCancelHook} className="filter-modal_cancel">
                Cancel
              </div>
              <div onClick={onApplyHook} className="filter-modal_apply">
                Apply
              </div>
            </div>
          </div>
        </Portal>
      )}
    </React.Fragment>
  );
};

export default FilterModal;
