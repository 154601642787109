import React from "react";
import { Helmet } from "react-helmet-async";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import { usePatches } from "lib/hooks/json-hooks";
import { metaDefault, seoList } from "./champion-meta";

const ChampionPageSEO = (props) => {
  const { championId } = props;
  const { getChampionName, getNormalizedChampionName, getChampionImg } = getRiotAssetsContext();

  const { data: patches, loading: loadingPatches, error: errorPatches } = usePatches({ ssr: true });
  const patch = patches && patches[0].id.replace("_", ".");

  const championName = getChampionName(championId);
  const normalizedChampionName = getNormalizedChampionName(championId);
  let { title, description } = seoList[normalizedChampionName] || metaDefault;

  title = title.replace(/\[Champion\]/g, championName).replace(/\[Patch\]/g, patch);
  description = description.replace(/\[Champion\]/g, championName).replace(/\[Patch\]/g, patch);
  const imgUrl = getChampionImg(championId);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content={imgUrl} />
    </Helmet>
  );
};

export default ChampionPageSEO;
