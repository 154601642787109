import React from "react";
import classnames from "classnames";
import { ReactComponent as Checkmark } from "svg/checkmark.svg";

const Checkbox = (props) => {
  const { children, id, className, value, onChange, checked, inactive, readOnly } = props;

  const classNames = classnames("custom-checkbox", className, {
    inactive,
  });

  return (
    <label className={classNames}>
      {children}
      <input type="checkbox" value={""} onChange={onChange} checked={!!checked} readOnly={readOnly} />
      <div className="styled-checkbox">
        <Checkmark />
      </div>
    </label>
  );
  // return (
  //   <div className={classNames}>
  //     <label>
  //       {children}
  //       <input type="checkbox"
  //         value={""}
  //         onChange={onChange}
  //         checked={!!checked}
  //         readOnly={readOnly}
  //       />
  //       <div className="styled-checkbox">
  //         <Checkmark />
  //       </div>
  //     </label>
  //   </div>
  // )
};

export default Checkbox;
