import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import classnames from "classnames";
import Highcharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import { getRoleImgURL } from "lib/role-helpers";

const TopPicksChart = (props) => {
  const { className, topPicks, dupeChampions } = props;
  const { getChampionName, getNormalizedChampionName, getChampionImg } = getRiotAssetsContext();

  const history = useHistory();
  const [windowBreakpoint] = useGlobal("responsive");
  const { data, min, max } = topPicks;

  const chartData = useMemo(() => {
    return data.map((el) => ({
      ...el,
      x: el.pickRate,
      y: el.winRate,
    }));
  }, [topPicks]);

  const isMobile = windowBreakpoint === "TABLET" || windowBreakpoint === "MOBILE_LARGE" || windowBreakpoint === "MOBILE_SMALL";
  const topPicksChartClassNames = classnames("top-picks-chart", className);
  return (
    <div className={topPicksChartClassNames}>
      <div style={{ width: "100%" }}>
        <HighChartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "scatter",
              reflow: true,
              height: isMobile ? 500 : 662,
              spacingTop: 40,
              spacingBottom: 40,
              spacingLeft: 50,
              spacingRight: 30,
            },
            series: [
              {
                name: "Top Picks",
                data: chartData,
                allowPointSelect: true,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  allowOverlap: true,
                  inside: true,
                  verticalAlign: "middle",
                  formatter: function () {
                    const { championId, role } = this.point;
                    const url = `/champion/${getNormalizedChampionName(championId)}`;
                    const showRole = dupeChampions.includes(championId);
                    return `
                  <div class="champion-plot-image">
                    <div class="champion-image">
                      <img src=${getChampionImg(championId)} />
                    </div>
                    ${
                      showRole
                        ? `
                        <div class="role">
                          <img src=${getRoleImgURL(role)} />
                        <div>
                      `
                        : ""
                    }
                  </div>
                `;
                  },
                },
                point: {
                  events: {
                    // TODO
                    click: function () {
                      const url = `/champion/${getNormalizedChampionName(this.championId)}`;
                      history.push(url);
                    },
                  },
                },
              },
            ],
            title: {
              text: "",
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              distance: 18,
              useHTML: true,
              formatter: function () {
                return `
                <div class="plot-tooltip">
                  <div class="champion-name">${getChampionName(this.point.championId)}</div>
                  <div style="margin-bottom:3px"><span class="label">Pick Rate: </span><strong class="value">${
                    Math.round(this.x * 1000) / 10
                  }%</strong></div>
                  <div><span class="label">Win Rate: </span><strong class="value">${
                    Math.round(this.y * 1000) / 10
                  }%</strong></div>
                </div>
              `;
              },
            },
            xAxis: {
              // pick rate
              tickLength: 0,
              min: min.pickRate - 0.01 <= 0 ? 0 : min.pickRate - 0.01,
              max: max.pickRate + 0.01 >= 100 ? 100 : max.pickRate + 0.01,
              title: {
                enabled: true,
                useHTML: true,
                y: 12,
                text: `
                <div style="display:flex;align-items:center">
                  <span style="margin-right:6px;color: #bec3e1;font-family: Roboto;font-size: 16px;">Pick Rate</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
                    <g><g><path fill="#f8b218" d="M24 6l-8 6V7H0V5h16V0z"/></g></g>
                  </svg>
                </div>
              `,
              },
              labels: {
                y: 36,
                useHTML: true,
                formatter: function () {
                  return `<strong style="font-family:Roboto;font-size:16px;font-weight:700;color:#ffffff">${(
                    this.value * 100
                  ).toFixed(0)}</strong>`;
                },
              },
            },
            yAxis: {
              // win rate
              tickLength: 0,
              min: min.winRate - 0.05 <= 0 ? 0 : min.winRate - 0.05,
              max: max.winRate + 0.05 >= 100 ? 100 : max.winRate + 0.05,
              title: {
                enabled: true,
                useHTML: true,
                x: -24,
                text: `
                <div style="display:flex;align-items:center">
                  <span style="margin-right:6px;color: #bec3e1;font-family: Roboto;font-size: 16px;">Win Rate</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
                    <g><g><path fill="#3273fa" d="M24 6l-8 6V7H0V5h16V0z"/></g></g>
                  </svg>
                </div>
              `,
              },
              labels: {
                x: -24,
                useHTML: true,
                formatter: function () {
                  return `<strong style="font-family:Roboto;font-size:16px;font-weight:700;color:#ffffff">${(
                    this.value * 100
                  ).toFixed(0)}</strong>`;
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default TopPicksChart;
