import React, { useState, useEffect, useMemo } from "react";
import { useGlobal } from "reactn";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import ChampionMatches from "./ChampionMatches";
import ChampionSideColumn from "./side/ChampionSideColumn";
import ChampionTopBar from "./top/ChampionTopBar";
// import WorldsTagNotice from "components/common/WorldsTagNotice";
import { useQueryParams } from "lib/hooks/general-hooks";
import { validateQueryParams } from "query-params/param-helpers";
import { ReactComponent as Spinner } from "svg/spinner.svg";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  CHAMPION_MATCH_LIST,
  CHAMPION_ROLE_PERCENTAGES,
  CHAMPION_RUNE_PERCENTAGES,
  TOP_PROS_FOR_CHAMPION,
  CHAMPION_SPELLS,
  CHAMPION_WIN_RATE,
  CHAMPION_SKILL_PRIORITY,
} from "graphql/queries";
import { TRASH_PLAYER_KEY, TRASH_TEAM_KEY, TRASH_LEAGUE_KEY } from "components/Settings/pages/Trashcan/TrashcanPage";

function filterLocalTrash(data, filterBy) {
  if (!data) return null;

  const filteredList = [];
  data.forEach((el) => {
    const [type, value] = el.split(/_(.+)/);
    if (filterBy === type) {
      filteredList.push(value);
    }
  });
  return filteredList;
}

const SIDE_COLUMN_QUERIES = gql`
  query getSideColumn(
    $championId: Int!,
    $role: String,
    $leagueId: String,
    $opponentChampionId: Int,
    $isWorlds: Boolean,
    $isOtp: Boolean
  ) {
    ${CHAMPION_ROLE_PERCENTAGES}
    ${CHAMPION_RUNE_PERCENTAGES}
    ${TOP_PROS_FOR_CHAMPION}
    ${CHAMPION_SPELLS}
    ${CHAMPION_SKILL_PRIORITY}
    ${CHAMPION_WIN_RATE}
  }
`;

const ChampionPageContent = (props) => {
  const { championId } = props;
  const { getChampionIdByName } = getRiotAssetsContext();

  const queryParams = useQueryParams();
  const fullQueryParams = validateQueryParams(queryParams, "champion", { keepDefaultParams: true });
  const [localTrash, setLocalTrash] = useGlobal("localTrash");
  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");
  const [mounted, setMounted] = useState(!localTrash);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  const proBlocklist = useMemo(() => filterLocalTrash(localTrash, TRASH_PLAYER_KEY), [localTrash]);
  const teamBlocklist = useMemo(() => filterLocalTrash(localTrash, TRASH_TEAM_KEY), [localTrash]);
  const leagueBlocklist = useMemo(() => filterLocalTrash(localTrash, TRASH_LEAGUE_KEY), [localTrash]);

  const matchListVariables = {
    championId: parseInt(championId),
    // ...fullQueryParams.matchType === "suggested" && { recommendedFirst: true },
    ...(fullQueryParams.role !== "all" && { role: fullQueryParams.role }),
    ...(fullQueryParams.league !== "all" && { league: fullQueryParams.league }),
    // ...fullQueryParams.matchOutcome !== "all" && { victoryOnly: true },
    ...(fullQueryParams.matchup !== "all" && { opponentChampionId: parseInt(getChampionIdByName(fullQueryParams.matchup)) }),
    ...(fullQueryParams.isWorlds !== undefined && worldsPromo && fullQueryParams.isWorlds !== false && { isWorlds: true }),
    ...(fullQueryParams.isOtp !== undefined && fullQueryParams.isOtp !== false ? { isOtp: true } : { isOtp: false }),
    ...(proBlocklist && { proBlocklist }),
    ...(teamBlocklist && { teamBlocklist }),
    ...(leagueBlocklist && { leagueBlocklist }),
  };

  /*const { data: reccMatchList, loading: loadingReccMatchList, error: errorReccMatchList } = useQuery(CHAMPION_MATCH_LIST, {
    variables: {
      ...matchListVariables,
      recommendedFirst: true
    }
  })*/
  const {
    data: recentMatchList,
    loading: loadingRecentMatchList,
    error: errorRecentMatchList,
  } = useQuery(CHAMPION_MATCH_LIST, {
    variables: {
      ...matchListVariables,
    },
  });
  //console.log(fullQueryParams)
  //console.log(fullQueryParams.isOtp)
  //console.log(recentMatchList)
  //console.log(errorRecentMatchList)
  const {
    data: sideColumn,
    loading: loadingSideColumn,
    error: errorSideColumn,
  } = useQuery(SIDE_COLUMN_QUERIES, {
    variables: {
      championId: parseInt(championId),
      // ...fullQueryParams.matchType === "suggested" && { recommendedFirst: true },
      ...(fullQueryParams.role !== "all" && { role: fullQueryParams.role }),
      ...(fullQueryParams.league !== "all" && { leagueId: fullQueryParams.league }),
      // ...fullQueryParams.matchOutcome !== "all" && { victoryOnly: true },
      ...(fullQueryParams.matchup !== "all" && { opponentChampionId: parseInt(getChampionIdByName(fullQueryParams.matchup)) }),
      ...(fullQueryParams.isWorlds !== undefined && worldsPromo && fullQueryParams.isWorlds !== false && { isWorlds: true }),
      ...(fullQueryParams.isOtp !== undefined && fullQueryParams.isOtp !== false ? { isOtp: true } : { isOtp: false }),
      //championId: parseInt(championId),
      //...fullQueryParams.league !== "all" && { leagueId: fullQueryParams.league },
      //...fullQueryParams.role !== "all" && { role: fullQueryParams.role },
      //...fullQueryParams.league !== "all" && { league: fullQueryParams.league },
      //...fullQueryParams.matchup !== "all" && { opponentChampionId: parseInt(getChampionIdByName(fullQueryParams.matchup)) }
    },
  });

  //console.log(championId)
  //console.log(fullQueryParams.league)
  //console.log(sideColumn)
  //console.log(errorSideColumn)
  if (!mounted) {
    return null;
  }

  if (loadingRecentMatchList && loadingSideColumn) {
    return (
      <div className="champion-page_content champion-page_content__loading">
        <Spinner />
      </div>
    );
  }
  // else if (error) {
  //   return (
  //     <div className="champion-page_content champion-page_content__error">
  //     </div>
  //   )
  // }

  //const { getProChampionMatchList: recommended } = reccMatchList || {};
  const { getProChampionMatchList: recent } = recentMatchList || {};
  const loading = /* loadingReccMatchList || */ loadingRecentMatchList || loadingSideColumn;

  return (
    <React.Fragment>
      {/*
        !loading &&
        ((recommended && recommended.matchList.length > 0) ||
        (recent && recent.matchList.length > 0)) &&
        <WorldsTagNotice />
      */}

      <div className="champion-page_content">
        <ChampionTopBar
          championId={championId}
          loading={loading}
          filters={fullQueryParams}
          data={{
            ...sideColumn,
            mostPopularItems: recent && recent.mostPopularItems,
            mostPopularBoots: recent && recent.mostPopularBoots,
          }}
          matches={{
            //recommended: recommended && recommended.matchList,
            recent: recent && recent.matchList,
          }}
        />
        <ChampionMatches
          championId={championId}
          loading={loading}
          filters={fullQueryParams}
          matchListVariables={matchListVariables}
          mostPopularItems={recent && recent.mostPopularItems}
          matches={{
            //recommended: recommended && recommended.matchList,
            recent: recent && recent.matchList,
          }}
        />
        <ChampionSideColumn
          championId={championId}
          loading={loading}
          filters={fullQueryParams}
          data={{
            ...sideColumn,
            mostPopularItems: recent && recent.mostPopularItems,
          }}
          matches={{
            //recommended: recommended && recommended.matchList,
            recent: recent && recent.matchList,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ChampionPageContent;
