import React from "react";

const ErrorSection = (props) => {
  const { errorMessage } = props;
  const message = errorMessage || "An error has occurred. Please try again later.";
  return (
    <div className="error-section">
      <img className="chibi" src="https://static.bigbrain.gg/assets/lol/art/chibis/chibi-yasuo-desktop.png" />
      <div>{message}</div>
    </div>
  );
};

export default ErrorSection;
