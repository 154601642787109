import React from "react";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import ChampionRoles from "../side/ChampionRoles";
import ChampionRunes from "../side//ChampionRunes";
import ChampionItems from "../side//ChampionItems";
import ChampionSkills from "../side//ChampionSkills";
import ChampionSpells from "../side/ChampionSpells";
import WinRate from "../side/WinRate";
import ChampionBoots from "../side/ChampionBoots";
// import VideoAd from "../../Ads/VideoAd";
import { MediaQuery } from "@outplayed/responsive";

const ChampionTopBar = (props) => {
  const { getChampionName } = getRiotAssetsContext();
  const { championId, data, loading, matches, filters } = props;
  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const {
    data: seoChampionNames,
    loading: loadingSEONames,
    error: errorSEONames,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/seo-champion-names.json`, { ssr: true });
  const championName = getChampionName(championId);
  const seoChampion = seoChampionNames && seoChampionNames[championId];
  const { /*recommended,*/ recent } = matches;

  const {
    getProChampionRolePercentages,
    getProChampionRunePercentages,
    getTopProsForChampion,
    getProChampionSpellPercentages,
    getProChampionSkillPriority,
    getProChampionWinRate,
    mostPopularItems,
    mostPopularBoots,
  } = data || {};

  if (!Object.values(data).some((el) => !!el) || !seoChampionNames) {
    return <div className="champion-page_top-bar" />;
  }
  //console.log("matches",matches)
  //console.log("recommended",recommended)
  //console.log("recent", recent)
  if (!recent || (recent && recent.length === 0)) {
    return <div className="champion-page_top-bar" />;
  }

  return (
    !loading &&
    data && (
      <div className="champion-page_top-bar">
        <div className="champion-page_top-bar-stats">
          <WinRate data={getProChampionWinRate} championId={championId} championName={championName} recent={recent} />
          <ChampionRoles data={getProChampionRolePercentages} filters={filters} />
          <ChampionRunes data={getProChampionRunePercentages} championName={championName} />
          <ChampionBoots championName={championName} data={mostPopularBoots} />
          <ChampionItems championName={championName} data={mostPopularItems} />
          <ChampionSpells championId={championId} championName={championName} data={getProChampionSpellPercentages} />
          <ChampionSkills championId={championId} skillOrder={getProChampionSkillPriority} slotBgColor="#111129" />
          {/*
            <TopChampionRegions data={getTopProsForChampion} championId={championId} championName={championName} />
            <TopChampionPros data={getTopProsForChampion} roles={getProChampionRolePercentages} championName={championName} />
          */}
        </div>
        {/* <MediaQuery at="DESKTOP_LARGE" isClient renderNullOnFail>
          <VideoAd minWidth={400} minHeight={225} />
        </MediaQuery> */}
      </div>
    )
  );
};

export default ChampionTopBar;
