import moment from "moment";

moment.defineLocale("match", {
  // Update relative time
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "1m",
    mm: "%dm",
    h: "1hr",
    hh: "%dhr",
    d: "1d",
    dd: "%dd",
    M: "a mo",
    MM: "%dmo",
    y: "a yr",
    yy: "%dyr",
  },
});
