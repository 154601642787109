import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const RoleSplit = (props) => {
  const { className, value, options, onFilterChange } = props;
  const roleClassName = classnames("filter-select filter_role-split", className);
  const { t } = useTranslation();

  return (
    <div className={roleClassName}>
      {options.map((role) => {
        const optionClassName = classnames({
          "role-option": true,
          active: value === role.value,
        });
        return (
          <div key={role.value} className={optionClassName} onClick={() => props.onFilterChange("role", role)}>
            {role.img}
            <span className="role-label" style={{ marginLeft: "10px" }}>
              {t(role.label)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RoleSplit;
