import { window } from "global";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MediaQuery } from "@outplayed/responsive";
import { getStaticPlayer2Url, getTeamUrl, getLeagueSeriesUrl } from "@outplayed/riot-assets";

import Image from "components/common/Image";
import ProHeadshot from "components/common/ProHeadshot";
import Role from "components/Filters/filter-types/Role";
import RoleSplit from "components/Filters/filter-types/RoleSplit";
import League from "components/Filters/filter-types/League";
import Team from "components/Filters/filter-types/Team";
import { ROLES, getRoleId } from "lib/role-helpers";
import { ALL_ROLE_OPTIONS, LEAGUE_OPTIONS } from "query-params/filter-options";
import { useProList } from "lib/hooks/json-hooks";

const ProLink = ({ pro }) => {
  const observerNode = useRef();
  const observer = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (setShow && observer.current) {
      return observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShow(true);
        }
      },
      { threshold: 0 },
    );

    if (observerNode.current) {
      observer.current.observe(observerNode.current);
    }

    return () => observer.current.disconnect();
  }, [setShow]);

  return (
    <Link ref={observerNode} to={`/pro/${pro.normalized_name}`} className="pro-link">
      {show && (
        <React.Fragment>
          <div className="headshot-container">
            <div className="pro-headshot">
              <ProHeadshot src={getStaticPlayer2Url(pro.normalized_name)} />
            </div>
            <div className="league-icon">
              <img src={getLeagueSeriesUrl(pro.league)} alt={pro.league} />
            </div>
          </div>
          <div className="player-info">
            <div className="player-name">{pro.official_name}</div>
            <div className="player-team">
              <div className="team-icon">
                <Image src={getTeamUrl(pro.current_team)} />
              </div>
              <div className="team-name">{pro.current_team}</div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Link>
  );
};

const ProHomePage = (props) => {
  const [role, setRole] = useState(ROLES.ALL);
  const [league, setLeague] = useState(LEAGUE_OPTIONS[0].value);
  const [team, setTeam] = useState("all");
  const { data: proList, loading: loadingProList } = useProList();

  const onFilterChange = (key, option) => {
    if (key === "role") {
      setRole(option.value);
    } else if (key === "league") {
      setLeague(option.value);
    } else if (key === "team") {
      setTeam(option.value);
    }
  };

  const sortedPros = useMemo(() => {
    if (!proList) return [];

    return Object.values(proList)
      .filter((pro) => {
        return (
          (role === ROLES.ALL || getRoleId(pro.main_role) === getRoleId(role)) &&
          (league === LEAGUE_OPTIONS[0].value || league === pro.league.toLowerCase()) &&
          (team === "all" || team === pro.current_team)
        );
      })
      .sort((a, b) => a.official_name.localeCompare(b.official_name));
  }, [proList, role, league, team]);

  return (
    <div className="content-side-padding common-page pro-home-page">
      <Helmet>
        <title>Full Pro List for Best New Pro Builds | Probuildstats</title>
        <meta
          name="description"
          content="Probuildstats has the most complete and up-to-date pro list for probuilds for every champion and role. Get top builds from the best pros in every region."
        />
      </Helmet>
      <div className="common-page_header pro-home-page_header">
        <h1>Probuilds Pro Player Database</h1>
        <h5>
          Probuildstats has the most extensive and up-to-date pro player database yet. Pro pages have solo queue matches from all
          of a player's known accounts, as well as tags for their best champions and accolades. To find a player, filter by Role,
          Region/League, or Team, or search the pro's name at the top of this page.
        </h5>
      </div>
      <div className="filter-manager common-page_filters champion-home-page_filters">
        <div className="filter-manager_scroll-wrapper">
          <div className="filter-manager_main">
            <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
              <div className="flex align-center">
                <Role value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} isMobile />
                <League value={league} options={LEAGUE_OPTIONS} onFilterChange={onFilterChange} isMobile />
                <Team value={team} onFilterChange={onFilterChange} isMobile />
              </div>
            </MediaQuery>
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              <div className="flex align-center">
                <RoleSplit value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} />
                <League value={league} options={LEAGUE_OPTIONS} onFilterChange={onFilterChange} />
                <Team value={team} onFilterChange={onFilterChange} />
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
      <div className="common-page_content pro-home-page_content">
        <div className="pro-list">
          {sortedPros.map((pro) => (
            <ProLink key={pro.normalized_name} pro={pro} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProHomePage;
