import React, { useState } from "react";
import classnames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import MatchSummary from "./MatchSummary";
import MatchBuild from "./MatchBuild";
import MatchScoreboard from "./MatchScoreboard";
import { amplitudeLog, getKDARatio } from "lib/general-helpers";
import { getCompletedItemsCount } from "lib/item-helpers";
import moment from "moment";

function getAmplitudeTags(index, recommended, match) {
  const { getChampionName } = getRiotAssetsContext();
  const kdaRatio = getKDARatio(match.totalKills, match.totalDeaths, match.totalAssists);
  let kdaType;
  if (kdaRatio === "Perfect") {
    kdaType = "perfect";
  } else if (kdaRatio === "0.00") {
    kdaType = "0";
  } else if (kdaRatio >= 5) {
    kdaType = "high";
  } else if (kdaRatio >= 3) {
    kdaType = "medium";
  } else if (kdaRatio >= 1) {
    kdaType = "low";
  } else {
    kdaType = "very-low";
  }
  const matchAgeInDays = moment().diff(moment(match["matchTimestamp"]), "days");
  const completedItemsCount = getCompletedItemsCount(match.finalBuild);
  const matchDurationRounded = Math.floor(match.matchDuration / 60);

  const amplitudeTags = {
    win: match.win,
    version: match.version,
    "champion-id": match.championId,
    "champion-name": getChampionName(match.championId),
    "pro-league": match.proInfo.league,
    "pro-team": match.proInfo.currentTeam,
    "pro-name": match.proInfo.officialName,
    "match-type": recommended ? "best-matches" : "solo-queue",
    "match-index": index + 1,
    "match-age-in-days": matchAgeInDays,
    "kda-type": kdaType,
    "completed-items": completedItemsCount,
    "match-duration": matchDurationRounded,
  };

  return amplitudeTags;
}

const MatchCard = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tab, setTab] = useState("build");
  const { index, type, match, mostPopularItems, even, recommended, noTag, disableAmplitude, isLanding, world } = props;
  //console.log(match)
  if (!match) {
    return null;
  }

  const amplitudeTags = getAmplitudeTags(index, recommended, match);

  const onDropdown = (bool, index) => {
    if (bool) {
      if (!disableAmplitude && (type !== "champion" || type !== "pro")) {
        amplitudeLog("click-match-card", amplitudeTags);
      }
    }
    setDropdownOpen(bool);
  };

  const tabs = [
    { key: "build", label: "Build" },
    { key: "postGame", label: "Post Game" },
  ];

  const matchClassNames = classnames("match-card", {
    even: even,
    win: match.win,
    loss: !match.win,
  });

  return (
    <div className={matchClassNames}>
      <MatchSummary
        index={index}
        type={type}
        match={match}
        mostPopularItems={mostPopularItems}
        even={even}
        noTag={noTag}
        recommended={recommended}
        dropdownOpen={dropdownOpen}
        setDropdownOpen={onDropdown}
        isLanding={isLanding}
        world={world}
      />
      {dropdownOpen && (
        <div className="match-card_dropdown">
          <div className="tabs">
            {tabs.map(({ key, label }) => (
              <div key={key} className={classnames("tab", { tab_active: tab === key })} onClick={() => setTab(key)}>
                {label}
              </div>
            ))}
          </div>
          {tab === "build" && <MatchBuild match={match} even={even} />}
          {tab === "postGame" && <MatchScoreboard match={match} />}
        </div>
      )}
    </div>
  );
};

export default MatchCard;
