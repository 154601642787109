import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { ReactComponent as NewTag } from "svg/new-tag.svg";
//import { ReactComponent as World } from "svg/world-logo.svg";

const MobileMenu = (props) => {
  const [menuOpen, setMenuOpen] = useGlobal("mobileMenuOpen");
  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const mobileMenuClassNames = classnames("mobile-menu-container", menuOpen && "mobile-menu-container_open");
  return (
    <div className={mobileMenuClassNames}>
      {menuOpen && <div className="mobile-menu_backdrop" onClick={closeMenu}></div>}
      <div className="mobile-menu">
        <NavLink to={"/champions"} onClick={closeMenu}>
          <span>CHAMPIONS</span>
        </NavLink>
        <NavLink to={"/pros"} onClick={closeMenu}>
          <span>PROS</span>
        </NavLink>
        <NavLink to={"/top-picks"} onClick={closeMenu}>
          <span>TOP 20 PICKS</span>
        </NavLink>
        {worldsPromo && (
          <NavLink to={"/world"} onClick={closeMenu}>
            <img className="world-logo" src={require("images/worlds-logo.png").default} alt="world-logo" />
            <span>BOOTCAMP</span>
            <NewTag className="new-tag" />
          </NavLink>
        )}
        <NavLink to={"/settings"} onClick={closeMenu}>
          <span>SETTINGS</span>
        </NavLink>
      </div>
    </div>
  );
};

export default MobileMenu;
