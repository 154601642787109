import React from "react";
import classnames from "classnames";
import Select from "react-select";
import FilterModal from "./FilterModal";

const FilterSelect = (props) => {
  const {
    className,
    title,
    group,
    options,
    placeholder,
    onChange,
    value,
    tabIndex,
    isSearchable,
    maxHeight,
    width,
    formatOptionLabel,
    onMenuClose,
    isMobile,
    isDisabled,
    error,
  } = props;

  const getSelectedOption = (value) => {
    return options.find((option) => option.value === value);
  };

  // Available custom styles at: https://react-select.com/styles
  let customStyles = {
    clearIndicator: () => {},
    container: () => {},
    control: () => {},
    dropdownIndicator: () => {},
    group: () => {},
    groupHeading: () => {},
    indicatorsContainer: () => {},
    indicatorSeparator: () => {},
    input: () => {},
    loadingIndicator: () => {},
    loadingMessage: () => {},
    menuPortal: () => {},
    multiValue: () => {},
    multiValueLabel: () => {},
    multiValueRemove: () => {},
    noOptionsMessage: () => {},
    option: () => {},
    placeholder: () => {},
    singleValue: () => {},
    valueContainer: () => {},
    menu: (base) => ({
      maxHeight: maxHeight || 500,
      zIndex: 10,
      width: "auto",
      borderRadius: "4px",
      backgroundColor: "#454566",
      overflow: "hidden",
      position: "absolute",
      top: "100%",
      left: "0",
      marginBottom: "8px",
      marginTop: "8px",
      cursor: "pointer",
    }),
    menuList: (base) => ({
      width: width,
      minWidth: width,
      zIndex: 10,
      fontFamily: "Helvetica, sans-serif",
      maxHeight: maxHeight || 500,
      overflowY: "auto",
      paddingTop: "0px",
      paddingBottom: "0px",
    }),
  };

  if (error) {
    customStyles = Object.assign(customStyles, {
      control: (provided, state) => ({
        ...provided,
        borderColor: "red !important",
      }),
    });
  }

  const curValue = getSelectedOption(value);
  const SelectComponent = (
    <React.Fragment>
      {
        // Need this because clicks don't trigger on parent <div>
        isMobile && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}></div>
      }
      <Select
        placeholder={placeholder !== undefined ? placeholder : "Select"}
        formatOptionLabel={formatOptionLabel}
        styles={customStyles}
        className={`filter-select ${className}`}
        classNamePrefix={`filter-select`}
        options={options}
        onChange={onChange}
        value={curValue}
        isSearchable={!!isSearchable}
        defaultValue={null}
        isClearable={false}
        onMenuClose={onMenuClose}
        isDisabled={isDisabled}
        tabIndex={tabIndex}
        id="long-value-select"
        instanceId="long-value-select"
      />
    </React.Fragment>
  );

  return (
    <div className={classnames("filter-select-wrapper", className)}>
      {isMobile ? (
        <FilterModal
          button={SelectComponent}
          title={title}
          group={group}
          options={options}
          curValue={curValue}
          onApply={onChange}
        />
      ) : (
        SelectComponent
      )}
    </div>
  );
};

export default FilterSelect;
