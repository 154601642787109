import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { Item } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import RuneTree from "components/common/RuneTree/RuneTree";
import StatShardTree from "components/common/RuneTree/StatShardTree";
import ItemTimeline from "components/common/ItemTimeline/ItemTimeline";
import SkillPath from "components/common/SkillPath/SkillPath";

import { ReactComponent as ChevronRight } from "svg/chevron-right.svg";

const CompletedItems = (props) => {
  const { items, patch } = props;
  const { useRiotMythicItems, useRiotCompletedItems } = getRiotAssetsContext();

  const [webpSupport] = useGlobal("webpSupport");
  const { data: mythicItems, loading: loadingMythicItems, error: errorMythicItems } = useRiotMythicItems();
  const { data: completedItems, loading: loadingCompletedItems, error: errorCompletedItems } = useRiotCompletedItems();

  return (
    <div className="items">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div
            className={classnames("item-image", {
              "mythic-item": mythicItems && mythicItems.includes(item),
              "completed-item": completedItems && completedItems.includes(item),
            })}
          >
            <Item itemId={item} patch={patch} webp={webpSupport} />
          </div>
          {index < items.length - 1 && <ChevronRight className="arrow" />}
        </React.Fragment>
      ))}
    </div>
  );
};

const MatchBuild = (props) => {
  const { match, even } = props;

  const [webpSupport] = useGlobal("webpSupport");
  const activeRunes = [
    match.runes.perk0,
    match.runes.perk1,
    match.runes.perk2,
    match.runes.perk3,
    match.runes.perk4,
    match.runes.perk5,
  ];
  const itemTimeline = match.itemPath.slice().reverse();

  return (
    <div className="match-build">
      <div className="runes">
        <RuneTree perkStyle={match.runes.primaryStyle} activePerks={activeRunes} isPrimary patch={match.version} />
        <div className="secondary-tree">
          <RuneTree perkStyle={match.runes.subStyle} activePerks={activeRunes} patch={match.version} />
          <div className="divider" />
          <StatShardTree activeShards={match.statShards} patch={match.version} />
        </div>
      </div>
      <div className="completed-items">
        <div className="grid-title">Completed Item Build</div>
        <CompletedItems items={match.completedItems} patch={match.version} />
      </div>
      <div className="item-path">
        <div className="grid-title">Item Path</div>
        <ItemTimeline items={itemTimeline} highlightItems />
      </div>
      <div className="abilities">
        <div className="grid-title">Ability Skill Order</div>
        <SkillPath
          championId={match.championId}
          skillOrder={match.skillOrders.split("")}
          slotBgColor={even ? "#111129" : "#1d1d39"}
        />
      </div>
    </div>
  );
};

export default MatchBuild;
