import React from "react";
import classnames from "classnames";

import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { StatShard } from "@outplayed/tooltips";

const StatShardTree = (props) => {
  const { useStatShards } = getRiotAssetsContext();
  const { activeShards, patch, ssr } = props;
  const { data } = useStatShards({ patch, ssr });
  const [statShardPatch, statShardJSON] = data || [];

  const buildPerks = (row) => {
    let zeroActive = true;
    const dataRow = statShardJSON?.[row];
    const list = dataRow.shards.map((shard, index) => {
      const isActive = shard.id === Number(activeShards[row]);
      if (isActive === true) zeroActive = false;
      const perkActive = isActive ? "shard shard-active" : "shard shard-inactive";

      const perkClassNames = classnames("shard", { shard_active: isActive });
      return (
        <div key={`${row}_${shard.id}`} className={perkClassNames}>
          <StatShard shardRow={row} shardId={shard.id} patch={patch} />
        </div>
      );
    });

    return (
      <div key={row} className="shards">
        {list}
      </div>
    );
  };

  if (!data || !activeShards || activeShards.every((shard) => shard === -1)) {
    return null;
  }

  return (
    <div className="stat-shards-container">
      <div className="shard-rows">{[0, 1, 2].map((row) => buildPerks(row))}</div>
    </div>
  );
};

export default StatShardTree;
