import React from "react";
import { getRoleImg } from "lib/role-helpers";

const ChampionRoles = (props) => {
  const { data, filters } = props;
  if (filters && filters.role !== "all") {
    return (
      <div className="champion-roles">
        <div className="side-column_title">Roles</div>
        <div className="side-column_grid-item top-roles">
          {
            <div key={filters.role} className="role">
              <div className="role-img">{getRoleImg(filters.role)}</div>
              <div className="rate">100%</div>
            </div>
          }
        </div>
      </div>
    );
  }
  const { __typename, ...roleProps } = data || {};
  const roles =
    data &&
    Object.entries(roleProps)
      .sort((a, b) => b[1] - a[1])
      .filter(([role, rate]) => Math.round(parseFloat(rate)) > 1);

  if (roleProps && Object.values(roleProps).every((role) => role === 0)) {
    return (
      <div className="champion-roles">
        <div className="side-column_title">Roles</div>
        <div className="side-column_grid-item top-roles"></div>
      </div>
    );
  }

  return (
    <div className="champion-roles">
      <div className="side-column_title">Roles</div>
      <div className="side-column_grid-item top-roles">
        {roles &&
          roles.slice(0, 2).map(([role, rate]) => (
            <div key={role} className="role">
              <div className="role-img">{getRoleImg(role)}</div>
              <div className="rate">{Math.round(parseFloat(rate))}%</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChampionRoles;
