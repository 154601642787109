import React from "react";
import { useLocation } from "react-router-dom";
import { MediaQuery } from "@outplayed/responsive";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";
import AdBoxWrapper from "components/common/Ads/AdBoxWrapper";
import styles from "stylesheets/variables.module.scss";

const ProSideColumn = () => {
  const location = useLocation();

  return (
    <div className="pro-page_side-column pw-side-column">
      <MediaQuery at="DESKTOP_LARGE" isClient renderNullOnFail>
        <AdBoxWrapper adStyles={{ display: "flex", alignItems: "center", justifyContent: "center" }} width={300} height={250}>
          <RampUnit
            type={TagBasedAdUnits.MediumRectATF}
            id="pro-med-rect-atf-1"
            pathname={location.pathname}
            search={location.search}
          />
        </AdBoxWrapper>
      </MediaQuery>
      <MediaQuery at="DESKTOP_LARGE" isClient renderNullOnFail>
        <AdBoxWrapper
          styles={{ position: "sticky", top: `calc(12px + ${styles.mastheadHeight})`, marginTop: 24 }}
          adStyles={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          minAdWidth={300}
          minAdHeight={250}
        >
          <RampUnit
            type={TagBasedAdUnits.MediumRectBTF}
            id="pro-side-med-rect-btf-1"
            pathname={location.pathname}
            search={location.search}
          />
        </AdBoxWrapper>
      </MediaQuery>
    </div>
  );
};

export default ProSideColumn;
