import { window } from "global";
import React from "react";
import { Redirect } from "react-router-dom";
import { STATIC_LOL_URL, getRiotAssetsContext } from "@outplayed/riot-assets";
import { useQueryParams } from "lib/hooks/general-hooks";
import { useProList } from "lib/hooks/json-hooks";
import { useJSONFetcher } from "@outplayed/json-fetcher";

function normalize(string) {
  if (typeof string !== "string") return null;

  return string
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "")
    .trim();
}

function checkChampionNicknames(champion, championNicknames) {
  for (const [key, value] of Object.entries(championNicknames)) {
    if (normalize(key) === normalize(champion)) {
      return key;
    }
    for (const nickname of value) {
      if (normalize(nickname) === normalize(champion)) {
        return key;
      }
    }
  }
  return null;
}

function checkPros(pro, proList) {
  for (const [key, value] of Object.entries(proList)) {
    const { official_name, normalized_name } = value;
    if (normalize(pro) === normalized_name || normalize(pro) === normalize(official_name)) {
      return normalized_name;
    }
  }
  return null;
}

const OpenSearch = (props) => {
  const { getChampionIdByName, getNormalizedChampionName } = getRiotAssetsContext();

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const { data: championNicknames, loadingChampionNicknames, error: errorChampionNicknames } = useJSONFetcher(url);
  const { data: proList, loading: loadingProList, error: errorProList } = useProList();
  const queryParams = useQueryParams();
  const { query } = queryParams;
  const searchQuery = query || "";
  let redirectURL = "/";

  if (!window || loadingProList || loadingChampionNicknames) {
    return null;
  }

  if (!searchQuery) {
    return <Redirect to="/" />;
  }

  const championNickname = checkChampionNicknames(searchQuery, championNicknames);
  const championId = championNickname ? getChampionIdByName(championNickname) : getChampionIdByName(searchQuery);
  const pro = checkPros(searchQuery, proList);

  if (championId) {
    const championName = getNormalizedChampionName(championId);
    redirectURL = `/champion/${championName}`;
  } else if (pro) {
    redirectURL = `/pro/${pro}`;
  }

  return <Redirect to={redirectURL} />;
};

export default OpenSearch;
