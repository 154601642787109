import { useMemo, useRef } from "react";
import { match } from "path-to-regexp";
import { useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import { Ramp, RampHandle, TaglessAdUnits } from "@outplayed/ramp";
import { BREAKPOINTS } from "../constants";
import { AppRoutes } from "lib/routes";

export const RampContainer = () => {
  const location = useLocation();
  const [windowBreakpoint] = useGlobal("responsive");
  const rampRef = useRef<RampHandle>();

  const adUnits = useMemo(() => {
    for (let path in adUnitsByPage) {
      if (match(path)(location.pathname)) {
        return adUnitsByPage[path][windowBreakpoint as WindowBreakpoints] || adUnitsByPage[path].default;
      }
    }
    return defaultAdUnits;
  }, [location.pathname, windowBreakpoint]);

  return (
    <Ramp
      ref={rampRef}
      PUB_ID={process.env.PW_PUB_ID}
      WEBSITE_ID={process.env.PW_WEBSITE_ID}
      pathname={location.pathname}
      search={location.search}
      pwUnits={adUnits}
    />
  );
};

function getAdUnitsPages(routes: Readonly<string[]>, adUnits: AdUnitsConfig) {
  const map: { [key: string]: AdUnitsConfig } = {};
  for (let route of routes) {
    map[route] = adUnits;
  }
  return map;
}

const defaultAdUnits = [{ type: TaglessAdUnits.BottomRail }, { type: TaglessAdUnits.CornerAdVideo }];

type WindowBreakpoints = keyof typeof BREAKPOINTS;
type AdUnitsConfig = { [key in WindowBreakpoints]?: Array<{ type: TaglessAdUnits }> } & {
  default: Array<{ type: TaglessAdUnits }>;
};

const adUnitsByPage: { [key: string]: AdUnitsConfig } = {
  [AppRoutes.HOME]: {
    default: [
      { type: TaglessAdUnits.LeftRail },
      { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
    ],
  },
  ...getAdUnitsPages([AppRoutes.CHAMPIONS_HOMEPAGE, AppRoutes.PROS_HOMEPAGE, AppRoutes.TOP_PICKS, AppRoutes.SETTINGS_TRASHCAN], {
    default: [{ type: TaglessAdUnits.BottomRail }, { type: TaglessAdUnits.CornerAdVideo }],
    DESKTOP_LARGE: [
      { type: TaglessAdUnits.LeftRail },
      { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
    ],
  }),
  ...getAdUnitsPages([AppRoutes.CHAMPION_PAGE, AppRoutes.PRO_PAGE], {
    default: [{ type: TaglessAdUnits.LeftRail }, { type: TaglessAdUnits.BottomRail }, { type: TaglessAdUnits.CornerAdVideo }],
    DESKTOP_LARGE: [
      { type: TaglessAdUnits.LeftRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
    ],
  }),
};
