import {
  CHAMPION_PAGE_OPTIONS,
  CHAMPION_PAGE_DEFAULT,
  PRO_PAGE_OPTIONS,
  PRO_PAGE_DEFAULT,
  WORLD_PAGE_OPTIONS,
  WORLD_PAGE_DEFAULT,
} from "./filter-options";

export const validParams = {
  champion: {
    role: CHAMPION_PAGE_OPTIONS.role,
    // matchType: CHAMPION_PAGE_OPTIONS.matchType,
    league: CHAMPION_PAGE_OPTIONS.league,
    // matchOutcome: CHAMPION_PAGE_OPTIONS.matchOutcome,
    isWorlds: CHAMPION_PAGE_OPTIONS.isWorlds,
    isOtp: CHAMPION_PAGE_OPTIONS.isOtp,
    default: CHAMPION_PAGE_DEFAULT,
    exemptions: ["matchup", "isWorlds", "isOtp"],
  },
  pro: {
    role: PRO_PAGE_OPTIONS.role,
    default: PRO_PAGE_DEFAULT,
    exemptions: ["matchup"],
  },
  world: {
    role: WORLD_PAGE_OPTIONS.role,
    default: WORLD_PAGE_DEFAULT,
    exemptions: ["matchup", "team"],
  },
};

export const globalExemptions = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "url",
  "gclid",
  "gtm",
  "aps_debug",
  "aps_preview",
  "aps_pause",
  "google_preview",
  "iu",
  "gdfp_req",
  "lineItemId",
  "creativeId",
  "amzn_debug_mode",
  "aps_test",
];
