import { useState } from "react";
import { useApolloClient } from "@apollo/client";

export function useMatches(query, variables) {
  const client = useApolloClient();
  const [fetchState, setFetchState] = useState({
    loading: false,
    data: undefined,
    error: undefined,
  });

  const getMatches = ({ query: otherQuery, variables: otherVariables } = {}) => {
    setFetchState({
      loading: true,
      data: undefined,
      error: undefined,
    });

    client
      .query({
        query: otherQuery || query,
        variables: otherVariables || variables,
      })
      .then((response) => {
        setFetchState({
          loading: false,
          data: response.data,
          error: undefined,
        });
      })
      .catch((error) => {
        setFetchState({
          loading: false,
          data: undefined,
          error: error,
        });
      });
  };

  return [getMatches, fetchState];
}
