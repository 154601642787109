import { window } from "global";
import { createProvider, setGlobal, addReducer } from "reactn";
// import { initJSONFetcher } from "@outplayed/json-fetcher";
import { initJSONFetcher } from "@outplayed/json-fetcher";
import { getWindowBreakpoint } from "lib/responsive-helpers";
import { checkWebpSupport } from "lib/general-helpers";

function getLocalTrash() {
  if (window) {
    const localTrash = JSON.parse(localStorage.getItem("pbs_trash"));
    if (localTrash && typeof localTrash === "object" && !Array.isArray(localTrash)) {
      return Object.keys(localTrash);
    }
    return localTrash;
  }
  return null;
}

// Used on both server and client
// Return Provider component to wrap app
export const reactnInit = (props = {}) => {
  const { req, initState = {} } = props;
  const Provider = createProvider({
    ...initState,
  });

  // initReducers(Provider);
  initJSONFetcher(Provider);

  return Provider;
};

// Initialized only on client side
export const setClientReactNState = async (Provider) => {
  Provider.setGlobal({
    webpSupport: await checkWebpSupport(),
    localTrash: getLocalTrash(),
    responsive: getWindowBreakpoint(window && window.innerWidth),
    mobileMenuOpen: false,
  });
};

const globalReactN = reactnInit();
export default globalReactN;
