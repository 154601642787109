import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { ChampionSkill } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const SkillRow = (props) => {
  const [webpSupport] = useGlobal("webpSupport");
  const { championId, skillOrder, skillImgs, skillSlot, skillKey, isPassive, slotBgColor, disableTooltip, language, ssr, skip } =
    props;

  const generateSkillOrder = (skillOrder, skillSlot, skillKey) => {
    const completeList = skillOrder.length < 18 ? skillOrder.concat(Array(18 - skillOrder.length).fill(null)) : skillOrder;

    return completeList.map((skill, index) => {
      const levelUp = skillSlot === skill || skillKey === skill;
      const skillSlotClassNames = classnames("skill-slot", { "level-up": levelUp });
      return (
        <div key={index} className={skillSlotClassNames} style={{ backgroundColor: slotBgColor }}>
          {levelUp && index + 1}
        </div>
      );
    });
  };

  return (
    <div className="skill-order-row">
      <div className="skill-img">
        <ChampionSkill
          championId={championId}
          skillSlot={skillSlot - 1}
          disableTooltip={disableTooltip}
          isPassive={isPassive}
          language={language}
          ssr
          skip
          webp={webpSupport}
        />
        <div className="label">{skillKey}</div>
      </div>
      <div className="skill-order">{generateSkillOrder(skillOrder, skillSlot, skillKey)}</div>
    </div>
  );
};

const SkillPath = (props) => {
  const { championId, skillOrder, patch, slotBgColor, disableTooltip, language, ssr, skip } = props;
  const { useChampionIndiv, getChampionSkillImgs } = getRiotAssetsContext();

  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { patch, lang: language, ssr, skip });
  const skillImgs = getChampionSkillImgs(championId, { patch, optionalData: championIndiv });

  const commonProps = {
    championId,
    skillOrder,
    skillImgs,
    slotBgColor,
    disableTooltip,
    ssr,
    skip,
  };

  return (
    <div className="skill-path">
      {skillOrder && skillOrder.length && (
        <div>
          <SkillRow {...commonProps} skillSlot={1} skillKey={"Q"} />
          <SkillRow {...commonProps} skillSlot={2} skillKey={"W"} />
          <SkillRow {...commonProps} skillSlot={3} skillKey={"E"} />
          <SkillRow {...commonProps} skillSlot={4} skillKey={"R"} />
          {/*
              includePassive &&
                <SkillOrder {...commonProps} isPassive skillKey={"P"} />
            */}
        </div>
      )}
    </div>
  );
};

export default SkillPath;
