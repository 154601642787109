import { window } from "global";
import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { useRouteMatch } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import Error404Page from "components/Error404/Error404Page";
import FilterManager from "components/Filters/FilterManager";

import ChampionHeader from "./ChampionHeader";
import ChampionPageContent from "./ChampionPageContent";
import ChampionPageSEO from "./ChampionPageSEO";

const ChampionPage = (props) => {
  const { getChampionIdByName, getChampionName, getChampionSplashImg } = getRiotAssetsContext();
  const [webpSupport] = useGlobal("webpSupport");
  const routeMatch = useRouteMatch();
  const { champion } = routeMatch.params || {};
  const championId = getChampionIdByName(champion);
  const championName = getChampionName(championId);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [championId]);

  if (!championId) {
    return <Error404Page />;
  }

  const backgroundStyle = {
    backgroundImage: `
      radial-gradient(630px at 53.64% -3.07%, rgba(7, 7, 32, 0.75) 0%, rgb(7, 7, 32) 100%, rgb(7, 7, 32) 100%),
      url(${getChampionSplashImg(championId, { webp: webpSupport })})
    `,
  };

  return (
    <div className="champion-page_bg" style={backgroundStyle}>
      <ChampionPageSEO championId={championId} />
      <div className="champion-page content-side-padding">
        <ChampionHeader championId={championId} />
        <div className="champion-page_filters">
          <FilterManager page="champion" />

          {/*<div className="filter-description">
            <span>For each champ, we prioritize pros by:&nbsp;</span>
            <strong>popularity, performance, and regional skill.</strong>
          </div>*/}
        </div>
        <ChampionPageContent championId={championId} />
      </div>
    </div>
  );
};

export default ChampionPage;
