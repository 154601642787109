import { window } from "global";
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import LandingPage from "components/LandingPage/LandingPage";
import ChampionHomePage from "components/ChampionHomePage/ChampionHomePage";
import ProHomePage from "components/ProHomePage/ProHomePage";
import ChampionPage from "components/Champions/ChampionPage";
import ProPage from "components/Pros/ProPage";
import TopPicksPage from "components/TopPicks/TopPicksPage";
import SettingsPage from "components/Settings/SettingsPage";
import Error404Page from "components/Error404/Error404Page";
import { useGlobal } from "reactn";
import HealthPage from "components/HealthCheck";

const MainContent = (props) => {
  // window && localStorage.removeItem("pbs_trash")
  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");
  useEffect(() => {
    setWorldsPromo(false); //Change this to open worlds promotion feature
  }, []);

  return (
    <div id="main-content">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/champions" component={ChampionHomePage} />
        <Route path="/champion/:champion" component={ChampionPage} />
        <Route path="/pros" component={ProHomePage} />
        <Route path="/pro/:player" component={ProPage} />
        <Route path="/top-picks" component={TopPicksPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/health-check" component={HealthPage} />
        <Route path="/*" component={Error404Page} />
      </Switch>
    </div>
  );
};

export default MainContent;
