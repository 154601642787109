import { getS13RankUrl } from "@outplayed/riot-assets";
import { getKDARatio, getKDAColor } from "lib/general-helpers";
import { getTierColor, getShortTierRank } from "lib/rank-helpers";

export function formatProMatch(data, _patch) {
  if (!data) return data;

  let maxDamage = 0;
  const teamsPlus = data.map((team) => {
    const { teamId, win, participants } = team;

    const participantsPlus = participants.map((player) => {
      if (player.damage > maxDamage) {
        maxDamage = player.damage;
      }
      const kdaRatio = getKDARatio(player.kills || 0, player.deaths || 0, player.assists || 0);
      return {
        ...player,
        other: {
          kdaRatio,
          kdaRatioColor: getKDAColor(kdaRatio),
          totalCS: player.cs + player.jungleCs,
          tierColor: getTierColor(player.rank && player.rank.tier),
          rankImgSrc: getS13RankUrl((player.rank && player.rank.tier) || "unranked", true),
          rankText: getShortTierRank((player.rank && player.rank.tier) || "unranked", player.rank && player.rank.rank),
        },
      };
    });

    return {
      ...team,
      participants: participantsPlus,
    };
  });

  return {
    maxDamage,
    teamsPlus,
  };
}
