import { window } from "global";
import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { useLocation, Link, NavLink } from "react-router-dom";
import classnames from "classnames";
import { MediaQuery } from "@outplayed/responsive";
import SearchBar from "components/SearchBar/SearchBar";
import { ReactComponent as Logo } from "svg/pbs-logo.svg";
import { ReactComponent as CogIcon } from "svg/cog-icon.svg";
import { ReactComponent as Menu } from "svg/menu.svg";
import { ReactComponent as NewTag } from "svg/new-tag.svg";
import { amplitudeLog } from "lib/general-helpers";
//import { ReactComponent as World } from "svg/world-logo.svg";

const Masthead = (props) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useGlobal("mobileMenuOpen");
  const isHome = location.pathname === "/";
  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");
  const mastheadClassNames = classnames({
    home: isHome,
    "menu-open": menuOpen,
  });

  return (
    <div id="masthead" className={mastheadClassNames}>
      <div className="masthead_left">
        <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
          <div className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
            <Menu className="menu-icon" />
          </div>
        </MediaQuery>
        <Link className="masthead_logo" to="/" aria-label="Home">
          <Logo />
        </Link>
        <MediaQuery min="TABLET" max="DESKTOP_LARGE">
          <div className="vertical-divider"></div>
          <div className="masthead_links">
            <NavLink className="masthead_link" to={"/champions"}>
              CHAMPIONS
            </NavLink>
            <NavLink className="masthead_link" to={"/pros"}>
              PROS
            </NavLink>
            <NavLink className="masthead_link" to={"/top-picks"}>
              <span>TOP 20 PICKS</span>
            </NavLink>
            {worldsPromo && (
              <NavLink className="masthead_link" to={"/world"}>
                <img className="world-logo" src={require("images/worlds-logo.png").default} alt="world-logo" />
                <span>BOOTCAMP</span>
                <NewTag className="new-tag" />
              </NavLink>
            )}
          </div>
        </MediaQuery>
      </div>
      <div className="masthead_center">{!isHome && <SearchBar isMasthead autoFocus />}</div>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        <div className="masthead_right">
          <Link
            className="masthead_icon"
            to="/settings/trashcan"
            onClick={() => amplitudeLog("click-settings")}
            aria-label="Settings"
          >
            <CogIcon className="cog-icon" />
          </Link>
        </div>
      </MediaQuery>
    </div>
  );
};

export default Masthead;
