import React, { useState } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";
import { MediaQuery } from "@outplayed/responsive";
import { TooltipContainer, Rune, Item, SummonerSpell } from "@outplayed/tooltips";
import {
  getLeagueSeriesUrl,
  getStaticPlayer2Url,
  getTeamUrl,
  getPlayerCountryFlag,
  getRiotAssetsContext,
} from "@outplayed/riot-assets";

import ProHeadshot from "components/common/ProHeadshot";
import Image from "components/common/Image";
import TrashcanModal from "components/Settings/pages/Trashcan/TrashcanModal";
import { getKDARatio, getKDAColor } from "lib/general-helpers";
import { getRoleImg } from "lib/role-helpers";
import { usePatches, useProAchievements } from "lib/hooks/json-hooks";
import { ReactComponent as ThreeWhiteDot } from "svg/three-white-dot.svg";
import { ReactComponent as BrushStroke } from "svg/brush-stroke-2.svg";
import { ReactComponent as TripleArrow } from "svg/triple-arrows.svg";
import { ReactComponent as TrashIcon } from "svg/trash-icon.svg";
import { getBlockImg } from "lib/region-helpers";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";

function getPriorityProTag(tags) {
  return tags.find((tag) => {
    return tag === "All Star" || tag === "Top Pro" || tag === "Top Region";
  });
}

// 6 completed items
function isFullBuild(items, completedItems = []) {
  return items && items.every((item) => completedItems.includes(item));
}

// mythic item < 20% agg pick rate
function isUniqueBuild(items, mythicItems = [], aggItems = []) {
  if (!items || !items.some((item) => mythicItems.includes(item))) {
    return false;
  }

  const mythicItem = items.find((item) => mythicItems.includes(item));
  return mythicItem && aggItems.some((item) => item.itemId === mythicItem && item.pickRate < 0.2);
}

const LeagueLabel = ({ proLeague, normalizedName }) => {
  const { data, loading, error } = useProAchievements();
  const proAdditional = data && data[normalizedName];
  const { nation } = proAdditional || {};

  const tooltipInfo = (
    <div className="match-summuary_league-label">
      {nation && <img src={getPlayerCountryFlag(nation)} alt={nation} />}
      <strong>{proLeague}</strong>
    </div>
  );

  return (
    <TooltipContainer tooltipInfo={tooltipInfo}>
      <img className="league-image" src={getLeagueSeriesUrl(proLeague)} alt={proLeague} />
    </TooltipContainer>
  );
};

const RegionLabel = ({ proLeague, normalizedName, regionId }) => {
  const { data, loading, error } = useProAchievements();
  const proAdditional = data && data[normalizedName];
  const { nation } = proAdditional || {};

  const tooltipInfo = (
    <div className="match-summuary_league-label">
      {nation && <img src={getPlayerCountryFlag(nation)} alt={nation} />}
      <strong>{nation}</strong>
    </div>
  );

  return (
    //<TooltipContainer tooltipInfo={tooltipInfo}>

    <img className="league-image" src={getBlockImg(proLeague)} alt={proLeague} />

    //</TooltipContainer>
  );
};

const MatchSummary = (props) => {
  const { index, type, match, mostPopularItems, even, noTag, recommended, dropdownOpen, setDropdownOpen, isLanding, world } =
    props;
  const { getChampionName, useRiotMythicItems, useRiotCompletedItems, getChampionImg } = getRiotAssetsContext();
  const { riotUserName, riotTagLine, proInfo, proLeague, championId, version } = match;

  const [webpSupport] = useGlobal("webpSupport");
  const [trashModal, setTrashModal] = useState(false);
  const { data: patches, loading: loadingPatches, error: errorPatches } = usePatches({ ssr: true });
  const {
    data: mythicItems,
    loading: loadingMythicItems,
    error: errorMythicItems,
  } = useRiotMythicItems({ patch: version, ssr: true });
  const {
    data: completedItems,
    loading: loadingCompletedItems,
    error: errorCompletedItems,
  } = useRiotCompletedItems({ patch: version, ssr: true });

  const championName = type === "champion" || type === "pro" ? getChampionName(championId) : "";
  const oppChampionName = getChampionName(match.opponentChampionId);
  const isLatestPatch = patches && patches.findIndex((patch) => patch.id === version) === 0;

  const hasTag = getPriorityProTag(proInfo.tags);
  const timestamp = moment(match.matchTimestamp).local("match").fromNow();
  const kdaRatio = getKDARatio(match.totalKills, match.totalDeaths, match.totalAssists);
  const kdaRatioColor = getKDAColor(kdaRatio);
  const patch = version.replace("_", ".");
  const items = [...match.finalBuild].slice(0, match.finalBuild.length - 1).sort((a, b) => (!b ? -1 : 1));
  const matchFullBuild = isFullBuild(items, completedItems);
  const matchUniqueBuild = isUniqueBuild(items, mythicItems, mostPopularItems);

  const displayWorldsIndicator = false;
  const isWorldsParticipant = displayWorldsIndicator && proInfo.tags.some((tag) => !!tag.match("Worlds 2021"));
  const summaryClassName =
    world && type !== undefined ? "match-summary-world" : type === undefined ? "match-summary-champion" : "match-summary";

  const backgroundStyleOdd = {
    backgroundImage: `linear-gradient(270deg, #111129 0%, #23233b 71%, #6f719f 100%)`,
    backgroundRepeat: "no-repeat",
  };
  const backgroundStyleEven = {
    backgroundImage: `linear-gradient(270deg, #1d1d39 0%, #23233b 71%, #6f719f 100%)`,
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="match-summary-container">
      {trashModal && <TrashcanModal proName={match.normalizedName} onConfirm={() => {}} onClose={() => setTrashModal(false)} />}
      {!isLanding && (type !== "champion" || type !== "pro") && (
        <MediaQuery min="TABLET" max="DESKTOP_LARGE">
          <div className="trash-btn" onClick={() => setTrashModal(true)}>
            <TrashIcon className="trash-icon" />
          </div>
        </MediaQuery>
      )}
      <div
        className={classnames(summaryClassName, { even: even })}
        onClick={() => setDropdownOpen(!dropdownOpen, index)}
        style={even && world ? backgroundStyleEven : world ? backgroundStyleOdd : {}}
      >
        {isWorldsParticipant && <BrushStroke className="worlds-bg" />}
        <div className="grid-item grid-item-time-stamp">
          <div className={classnames("match-date", { recommended })}>
            {world && <img className="world-logo" src={require("images/worlds-logo.png").default} alt="world-logo" />}
            <div className="timestamp">{timestamp}</div>
          </div>
        </div>
        <div className="grid-item grid-item-player-info">
          <div className="player-headshot">
            <div
              className={classnames("headshot", {
                "has-tag": hasTag && !noTag,
              })}
            >
              {proInfo.currentTeam !== "One Trick Pony" ? (
                getRoleImg(proInfo.mainRole) !== null ? (
                  <div>
                    <ProHeadshot
                      className="max-w-none"
                      src={getStaticPlayer2Url(match.normalizedName)}
                      alt={proInfo.officialName}
                    />
                    <div
                      style={{
                        position: "absolute",
                        borderRadius: "1px",
                        height: "14px",
                        width: "14px",
                        backgroundColor: "#1d1d39",
                        bottom: "-2px",
                        right: "-7px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="role-img">{getRoleImg(proInfo.mainRole)}</div>
                    </div>
                  </div>
                ) : (
                  <ProHeadshot
                    className="max-w-none"
                    src={getStaticPlayer2Url(match.normalizedName)}
                    alt={proInfo.officialName}
                  />
                )
              ) : (
                <img
                  className="champion-headshot"
                  src={getChampionImg(championId, { webp: webpSupport })}
                  alt={`The Champion Icon for ${championName}`}
                />
              )}
            </div>
            {hasTag && !noTag && <div className="tag">{hasTag}</div>}
          </div>
          <div className="player-info">
            {proInfo.currentTeam === "One Trick Pony" ? (
              <a
                className="name"
                target="_blank"
                href={`https://u.gg${getProfileOverviewUrl(proInfo.region, riotUserName, riotTagLine)}`}
              >
                {proInfo.officialName}
              </a>
            ) : (
              <Link className="name" to={`/pro/${match.normalizedName}`}>
                {proInfo.officialName}
              </Link>
            )}
            <div className="team">
              {!(type === undefined || type === "pro") && (
                <Image className="region-img" src={getBlockImg(proLeague, true)} alt={match.regionId} invisible />
              )}
              {type === "pro" ? (
                <Image className="team-logo-pro" src={getTeamUrl(proInfo.currentTeam)} alt={proInfo.currentTeam} invisible />
              ) : (
                <div className={proInfo.currentTeam !== "One Trick Pony" ? "team-logo" : "otp-logo"}>
                  <Image
                    // className={proInfo.currentTeam !== "One Trick Pony" ? "team-logo" : "otp-logo"}
                    src={getTeamUrl(proInfo.currentTeam)}
                    alt={proInfo.currentTeam}
                    invisible
                  />
                </div>
              )}
              <div className="team-name">{proInfo.currentTeam}</div>
            </div>
          </div>
        </div>
        {type === undefined ? (
          <div className="grid-item grid-item-champion-image">
            <Image className="region-img" src={getBlockImg(proLeague, true)} alt={proLeague} invisible />
          </div>
        ) : (
          <div className="grid-item grid-item-champion-image">
            <div className="champion-image">
              <img src={getChampionImg(championId, { webp: webpSupport })} alt={`The Champion Icon for ${championName}`} />
            </div>
          </div>
        )}

        {!(type === undefined) ? (
          <div className="grid-item grid-item-opp-image">
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>VS.</div>
            <div className="opp">
              <div className="champion-image">
                <img
                  src={getChampionImg(match.opponentChampionId, {
                    webp: webpSupport,
                  })}
                  alt={`The Champion Icon for ${oppChampionName}`}
                />
              </div>
              {/*<div className="vs-text" >VS.</div>*/}
            </div>
          </div>
        ) : (
          <div className="grid-item grid-item-champion-opp-image-champ_page">
            <div className="opp">
              <div className="champion-image">
                <img
                  src={getChampionImg(match.opponentChampionId, {
                    webp: webpSupport,
                  })}
                  alt={`The Champion Icon for ${oppChampionName}`}
                />
              </div>
              <div className="vs-text">VS.</div>
            </div>
          </div>
        )}
        <div className="grid-item grid-item-kda">
          <div className="kda">
            <span>{match.totalKills}</span>
            <span className="backslash"> / </span>
            <span style={{ color: "#ff4e50" }}>{match.totalDeaths}</span>
            <span className="backslash"> / </span>
            <span>{match.totalAssists}</span>
          </div>
          {/*          <div className="kda-ratio" style={{color: kdaRatioColor}}>
            {kdaRatio}
            <span style={{color: "#bec3e1", fontWeight: 400}}> KDA</span>
          </div>
          */}{" "}
        </div>
        <div className="grid-item grid-item-runes">
          <div className="runes">
            <div className="keystone">
              <Rune runeId={match.runes.perk0} patch={version} ssr webp={webpSupport} />
            </div>
            <div className="secondary-tree">
              <Rune runeId={match.runes.subStyle} patch={version} ssr webp={webpSupport} />
            </div>
          </div>
        </div>
        <div className="grid-item grid-item-spells">
          <div className="summoner-spells">
            {match.summonerSpells.map((spellId) => (
              <div key={spellId} className="spell-image">
                <SummonerSpell spellId={spellId} ssr webp={webpSupport} />
              </div>
            ))}
          </div>
        </div>
        <div className="grid-item grid-item-champion-items">
          <div className="items">
            {items.map((item, index) => (
              <div
                key={index}
                className={classnames("item-image", {
                  "mythic-item": mythicItems && mythicItems.includes(item),
                  "completed-item": completedItems && completedItems.includes(item),
                })}
              >
                {parseInt(item) > 0 && <Item itemId={item} patch={version} ssr webp={webpSupport} />}
              </div>
            ))}
            <div className="item-image trinket">
              <Item itemId={match.finalBuild[6]} patch={version} ssr webp={webpSupport} />
            </div>
            <div
              className={classnames("item-sort", {
                even,
                "full-build": matchFullBuild,
                "unique-build": matchUniqueBuild,
              })}
            >
              <TripleArrow />
              {(matchFullBuild || matchUniqueBuild) && (
                <div className="item-build-label">{matchUniqueBuild ? "Unique Build" : matchFullBuild ? "Full Build" : ""}</div>
              )}
            </div>
          </div>
        </div>
        <div className="grid-item grid-item-btn">
          <div className={classnames("dropdown-btn")}>
            <ThreeWhiteDot />
          </div>
        </div>
      </div>
      {dropdownOpen && (
        <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
          <div className="match-summary_condensed-row">
            <div className="opp">
              <div>VS.</div>
              <div className="champion-image">
                <img
                  src={getChampionImg(match.opponentChampionId, {
                    webp: webpSupport,
                  })}
                />
              </div>
            </div>
            {/*<MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE" renderNullOnFail>*/}
            <div className="summoner-spells">
              {match.summonerSpells.map((spellId) => (
                <div key={spellId} className="spell-image">
                  <SummonerSpell spellId={spellId} ssr webp={webpSupport} />
                </div>
              ))}
            </div>
            {/*</MediaQuery>*/}
            {/*<div className="timestamp">{timestamp}</div>
            <div className="patch">
              <div>{patch}</div>
              <div>Patch</div>
              </div>*/}
          </div>
        </MediaQuery>
      )}
    </div>
  );
};

export default MatchSummary;
