import { window, document } from "global";
import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const Portal = (props) => {
  const [mounted, setMounted] = useState(false);
  const el = useRef(document && document.createElement("div"));
  const { children, dest = "react-portal" } = props;

  useEffect(() => {
    setMounted(true);
    document && document.getElementById(dest).appendChild(el.current);

    return () => {
      document && document.getElementById(dest).removeChild(el.current);
    };
  }, []);

  if (!window || !mounted) {
    return null;
  }

  return createPortal(children, el.current);
};

export default Portal;
