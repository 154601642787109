import { window } from "global";
import React, { useCallback, useEffect } from "react";
import { useGlobal } from "reactn";
import { ResponsiveContextProvider } from "./ResponsiveContext";

export function getWindowBreakpoint(width, breakpoints, defaultBreakpoint) {
  if (!width && defaultBreakpoint) return defaultBreakpoint;

  for (let [breakpoint, breakpointWidth] of Object.entries(breakpoints)) {
    if (width >= breakpointWidth[0] && width <= breakpointWidth[1]) {
      return breakpoint;
    }
  }
  return defaultBreakpoint;
}

export const Responsive = (props) => {
  const { breakpoints, defaultBreakpoint } = props;
  const [responsive, setResponsive] = useGlobal("responsive");

  const resizeHandler = useCallback(() => {
    const breakpoint = getWindowBreakpoint(window.innerWidth, breakpoints);
    if (breakpoint !== responsive) {
      setResponsive(breakpoint);
    }
  }, [responsive]);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", resizeHandler);

      return () => window.removeEventListener("resize", resizeHandler);
    }
  }, [resizeHandler]);

  return React.createElement(
    ResponsiveContextProvider,
    {
      value: {
        breakpoints,
        defaultBreakpoint,
      },
    },
    props.children,
  );
};
