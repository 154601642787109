import React from "react";
import { useGlobal } from "reactn";
import { Rune } from "@outplayed/tooltips";

const ChampionRunes = (props) => {
  const { data, championName } = props;
  const [webpSupport] = useGlobal("webpSupport");

  if (!data || (data && data.length === 0)) {
    return (
      <div className="champion-runes">
        <div className="side-column_title">{championName} Runes</div>
      </div>
    );
  }

  return (
    <div className="champion-runes">
      <div className="side-column_title">Runes</div>
      <div className="side-column_grid-item top-runes">
        {data &&
          data.slice(0, 2).map((rune, index) => (
            <div key={index} className="runes">
              <div className="rune">
                <div className="rune-img">
                  <div className="keystone">
                    <Rune runeId={rune.perk0} ssr webp={webpSupport} />
                  </div>
                  <div className="secondary-tree">
                    <Rune runeId={rune.subStyle} ssr />
                  </div>
                </div>
              </div>
              <div className="rate">{Math.round(parseFloat(rune.percentage))}%</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChampionRunes;
