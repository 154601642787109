import React from "react";
import PageNav from "components/common/PageNav/PageNav";
import { ReactComponent as CogIcon } from "svg/cog-icon.svg";

const SettingsHeader = (props) => {
  const pages = [{ label: "Trashcan", path: "/settings/trashcan" }];

  return (
    <div className="settings-header">
      <h1 className="">
        <CogIcon className="cog-icon" />
        <span>Settings</span>
      </h1>
      <PageNav pages={pages} />
    </div>
  );
};

export default SettingsHeader;
