import { getBlockRegionUrlPBS } from "@outplayed/riot-assets";
import React from "react";
import { ReactComponent as BR } from "../svg/region/BR.svg";
import { ReactComponent as EU } from "../svg/region/EU.svg";
import { ReactComponent as JP } from "../svg/region/JP.svg";
import { ReactComponent as KR } from "../svg/region/KR.svg";
import { ReactComponent as LAN } from "../svg/region/LAN.svg";
import { ReactComponent as LAS } from "../svg/region/LAS.svg";
import { ReactComponent as NA } from "../svg/region/NA.svg";
import { ReactComponent as OCE } from "../svg/region/OCE.svg";
import { ReactComponent as RU } from "../svg/region/RU.svg";
import { ReactComponent as TR } from "../svg/region/TR.svg";

function getRegionLabel(regionId) {
  switch (regionId.toLowerCase()) {
    case "na1":
      return "NA";
    case "euw1":
      return "EUW";
    case "eun1":
      return "EUNE";
    case "kr":
      return "KR";
    case "br1":
      return "BR";
    case "la1":
      return "LAN";
    case "la2":
      return "LAS";
    case "oc1":
      return "OCE";
    case "ru":
      return "RU";
    case "tr1":
      return "TR";
    case "jp1":
      return "JP";
    case "ph2":
      return "PH";
    case "sg2":
      return "SG";
    case "th2":
      return "TH";
    case "tw2":
      return "TW";
    case "vn2":
      return "VN";
    default:
      return "";
  }
}
/*function getNewBlockImg(region, className) {
  switch (region) {
    case "world":
      return getBlockRegionUrl("World", isMasthead);
    case "LCS":
      return <NA className={{className}}></NA>;
    case "LEC":
      return <EU className={{className}}></EU>
    case "LCK":
      return "../svg/region/KR.svg";
    case "CBLOL":
      return <BR className={{className}}></BR>;
    case "jp1":
      return <JP className={{className}}></JP>;
    case "la1":
      return <LAN className={{className}}></LAN>;
    case "la2":
      return <LAS className={{className}}></LAS>;
    case "oc1":
      return <OCE className={{className}}></OCE>;
    case "ru":
      return <RU className={{className}}></RU>;
    case "TCL":
      return <TR className={{className}}></TR>;
    default:
      return "";
  }
  
}*/
function getBlockImg(region, isMasthead) {
  switch (region) {
    case "world":
      return getBlockRegionUrlPBS("World", isMasthead);
    case "CBLOL":
      return getBlockRegionUrlPBS("BR", isMasthead);
    case "LPL":
      return getBlockRegionUrlPBS("CN", isMasthead);
    case "LEC":
      return getBlockRegionUrlPBS("EU", isMasthead);
    case "LJL":
      return getBlockRegionUrlPBS("JP", isMasthead);
    case "LCK":
      return getBlockRegionUrlPBS("KR", isMasthead);
    case "LAN":
      return getBlockRegionUrlPBS("LAN", isMasthead);
    case "LAS":
      return getBlockRegionUrlPBS("LAS", isMasthead);
    case "LLA":
      return getBlockRegionUrlPBS("LAT", isMasthead);
    case "LCS":
      return getBlockRegionUrlPBS("NA", isMasthead);
    case "LCS Academy":
      return getBlockRegionUrlPBS("NA", isMasthead);
    case "LCO":
      return getBlockRegionUrlPBS("OCE", isMasthead);
    case "PCS":
      return getBlockRegionUrlPBS("PCS", isMasthead);
    case "LCL":
      return getBlockRegionUrlPBS("RU", isMasthead);
    case "TCL":
      return getBlockRegionUrlPBS("TR", isMasthead);
    default:
      return "";
  }
}

export { getRegionLabel, getBlockImg };
