import { window } from "global";
import React from "react";
import { render, hydrate } from "react-dom";
import { loadableReady } from "@loadable/component";
import { BrowserRouter } from "react-router-dom";
import App from "components/App";
import GoogleAnalytics from "components/GoogleAnalytics";
import TagManager from "react-gtm-module";
import globalReactN, { setClientReactNState } from "lib/reactn-init";
import { initApolloClient } from "components/ApolloWrapper";
import adblockDetect from "adblock-detect";
import "lib/local-moment";
import { HelmetProvider } from "react-helmet-async";
import amplitude from "amplitude-js";

// styling
import "stylesheets/index.scss";

import Highcharts from "highcharts";
import highchartsDefaultTheme from "highcharts/default";

Highcharts.theme = highchartsDefaultTheme;
Highcharts.setOptions(Highcharts.theme);

// Browser Auto Refresh
if (process.env.NODE_ENV === "development") {
  const ws = new WebSocket("ws://localhost:8080?client=web");

  ws.onerror = () => {
    console.log("browser-reload ws not running");
  };

  ws.onopen = () => {
    console.log("connection");
  };
  ws.onmessage = (e) => {
    if (e.data === "reload") {
      window.location.reload();
    }
  };
}

// Init Google Tag Manager
const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};
TagManager.initialize(tagManagerArgs);

// Init Amplitude
amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY);

// Add <meta charset="UTF-8"> to the top of header
// for Lighthouse best practices to work
if (document) {
  var head = document.getElementsByTagName("head")[0];
  var meta = document.createElement("meta");
  meta.setAttribute("charset", "UTF-8");
  head.insertBefore(meta, head.firstChild);
}

// Check if user has adblck
adblockDetect(async (detectedAdBlock) => {
  // Initialize reactn global state
  const preloadedReactNState = Object.assign({ detectedAdBlock }, window.__REACTN_PRELOADED_STATE__);
  const ReactNProvider = globalReactN;
  ReactNProvider.setGlobal(preloadedReactNState);
  await setClientReactNState(ReactNProvider);
  delete window.__REACTN_PRELOADED_STATE__;

  // console.log(preloadedReactNState)
  // console.log(ReactNProvider.getGlobal())
  // setGlobal(preloadedReactNState);

  const client = initApolloClient({ preloadedState: window.__APOLLO_STATE__ });
  delete window.__APOLLO_STATE__;

  loadableReady(() => {
    hydrate(
      <HelmetProvider>
        <ReactNProvider>
          <BrowserRouter>
            <GoogleAnalytics client={client} />
            <App client={client} />
          </BrowserRouter>
        </ReactNProvider>
      </HelmetProvider>,
      document.getElementById("root"),
    );
  });
});
