import { window } from "global";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import FilterModal from "../FilterModal";
import { ReactComponent as IconX } from "svg/x.svg";
import { ReactComponent as ArrowDown } from "svg/triangle-arrow-down.svg";
import { MediaQuery } from "@outplayed/responsive";
const Matchup = (props) => {
  const { className, value, onFilterChange, isMobile } = props;
  const { getChampionImg, getChampionIdByName, getChampionName, getNormalizedChampionName, useChampionMini } =
    getRiotAssetsContext();

  // console.log(backupChampion);
  const { data: championMini } = useChampionMini();

  const allChampions = Object.values(championMini).sort((a, b) => a.name.localeCompare(b.name));

  const mobileOptions = allChampions.map((champion) => ({
    label: champion.name,
    value: getNormalizedChampionName(champion.key),
  }));

  mobileOptions.unshift({
    label: "All Champions",
    value: "all",
  });

  const filterRef = useRef();
  const [webpSupport] = useGlobal("webpSupport");
  const [inputActive, setInputActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [highlightedItem, setHighlightedItem] = useState(0);

  const resetFilter = () => {
    setInputActive(false);
    setInputValue("");
    setHighlightedItem(0);
  };

  useEffect(() => {
    if (window && filterRef.current) {
      const outsideClick = (e) => {
        if (filterRef.current && e.target !== null && !filterRef.current.contains(e.target)) {
          resetFilter();
        }
      };
      window.addEventListener("click", outsideClick);

      return () => window.removeEventListener("click", outsideClick);
    }
  }, []);

  const onClick = (e) => {
    e.stopPropagation();
    !inputActive && setInputActive(true);
  };

  const onFilterSelect = (champion) => {
    const value = getNormalizedChampionName(champion.key);
    onFilterChange("matchup", { value });
    resetFilter();
  };

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const championList = useMemo(() => {
    if (!inputValue.trim()) return [];

    return allChampions
      .filter((champion) => {
        const normalizedChampionName = champion.name.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
        const normalizedInput = inputValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
        return normalizedChampionName.includes(normalizedInput);
      })
      .slice(0, 8);
  }, [inputValue]);

  const onKeyDown = (e) => {
    if (e.which === 13) {
      // enter
      onFilterSelect(championList[highlightedItem]);
    } else if (e.which === 38) {
      // up
      e.preventDefault();
      setHighlightedItem(Math.abs(championList.length + highlightedItem - 1) % championList.length);
    } else if (e.which === 40) {
      // down
      e.preventDefault();
      setHighlightedItem(Math.abs(championList.length + highlightedItem + 1) % championList.length);
    }
  };

  const championId = getChampionIdByName(value);
  const filterClassName = classnames("filter_matchup", className);

  const FilterComponent = (
    <React.Fragment>
      {
        // Need this because clicks don't trigger on parent <div>
        isMobile && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}></div>
      }
      <div className="filter-select filter_matchup" ref={filterRef}>
        <div className="matchup-control" onClick={onClick}>
          <span className="vs">vs.</span>
          <div className="matchup-label">
            {inputActive ? (
              <input placeholder="Search champion" value={inputValue} onChange={onChange} onKeyDown={onKeyDown} autoFocus />
            ) : (
              <div className="matchup-champion">
                {championId ? (
                  <div className="champion-image">
                    <img src={getChampionImg(championId, { webp: webpSupport })} />
                  </div>
                ) : (
                  <div className="all-champions">
                    <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
                      <span>All Champions</span>
                    </MediaQuery>
                    <MediaQuery at="MOBILE_SMALL">
                      <span>All</span>
                    </MediaQuery>
                    <ArrowDown />
                  </div>
                )}
              </div>
            )}
          </div>
          {/*
            !isMobile && championId && !inputActive &&
            <div className="clear-matchup"
              onClick={(e) => {
                e.stopPropagation();
                onFilterChange("matchup", {value: "all"});
              }}
            >
              <IconX className="x-icon" />
            </div>
            */}
        </div>
        {championList.length > 0 && (
          <div className="matchup-menu">
            {championList.map((champion, index) => {
              const className = classnames("matchup-menu_item", { highlighted: index === highlightedItem });
              return (
                <div
                  key={champion.key}
                  className={className}
                  onMouseEnter={() => setHighlightedItem(index)}
                  onClick={() => onFilterSelect(champion)}
                >
                  <img src={getChampionImg(champion.key, { webp: webpSupport })} />
                  <div className="champion-name">{champion.name}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <div className="filter-select-wrapper">
      {isMobile ? (
        <FilterModal
          button={FilterComponent}
          title={"Change Matchup"}
          group={"matchup"}
          options={mobileOptions}
          curValue={{
            label: getChampionName(championId),
            value: getNormalizedChampionName(championId),
          }}
          onApply={(selected) => onFilterChange("matchup", selected)}
        />
      ) : (
        FilterComponent
      )}
    </div>
  );
};

export default Matchup;
