import React from "react";
import { ReactComponent as PBSLogo } from "svg/pbs-logo.svg";
import { ReactComponent as BrushStroke } from "svg/brush-stroke.svg";
import { Helmet } from "react-helmet-async";

const Error404Page = (props) => {
  return (
    <div className="error-404-page content-side-padding">
      <Helmet>
        <title>Error: This page doesn't exist</title>
        <meta
          name="description"
          content="This page doesn't exist. Neither does NA's chance at worlds :(. The page you're looking for isn't available. Try to search again or use the back button."
        />
        <meta property="og:image" content="https://static.bigbrain.gg/assets/probuildstats/logos/pbs-logo.svg" />
      </Helmet>
      <div className="error_left">
        <h1>
          This page <br /> doesn't exist
        </h1>
        <span>Neither does NA’s chance at worlds :(</span>
        <strong>
          The page you're looking for isn't available. <br />
          Try to search again or use the back button.
        </strong>
      </div>
      <div className="error_right">
        <PBSLogo className="pbs-logo" />
        <BrushStroke className="brush-stroke" />
      </div>
    </div>
  );
};

export default Error404Page;
