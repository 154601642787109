export const AppRoutes = {
  HOME: "/",
  TOP_PICKS: "/top-picks",
  CHAMPIONS_HOMEPAGE: "/champions",
  CHAMPION_PAGE: "/champion/:champion",
  PROS_HOMEPAGE: "/pros",
  PRO_PAGE: "/pro/:player",
  SETTINGS: "/settings",
  SETTINGS_TRASHCAN: "/settings/trashcan",
  HEALTH_CHECK: "/health-check",
} as const;
