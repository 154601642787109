import { useJSONFetcher } from "@outplayed/json-fetcher";

export function useProList(options = {}) {
  return useJSONFetcher("https://stats2.u.gg/pro/pro-list.json", {
    onCompleted: (url, json) => {
      const prosByKey = {};
      json.forEach((pro) => {
        prosByKey[pro.normalized_name] = pro;
      });
      return prosByKey;
    },
    ...options,
  });
}

export function useProAchievements(options = {}) {
  return useJSONFetcher("https://stats2.u.gg/pro/pro-achievements-and-nationality-mini.json", options);
}

function compareNewestPatches(a, b) {
  if (a.start_time < b.start_time) return 1;
  if (a.start_time > b.start_time) return -1;
  return 0;
}

export function usePatches(options = {}) {
  return useJSONFetcher("https://lolpatches.s3.us-east-2.amazonaws.com/patches.json", {
    onCompleted: (url, data) => {
      if (data.patches) {
        // patch list can contain future patches, filter them out and sort by timestamp
        let patches = data.patches.filter((patch) => patch.start_time <= new Date().getTime() / 1000);
        patches = patches.sort(compareNewestPatches);
        return patches;
      } else {
        return undefined;
      }
    },
    ...options,
  });
}
