import React from "react";
import { MediaQuery } from "@outplayed/responsive";
import ProMatches from "./ProMatches";
import ProSideColumn from "./side/ProSideColumn";

const ProPageContent = (props) => {
  const { proJSON, achievements } = props;
  const { official_name, normalized_name, region_id } = proJSON;

  if (!proJSON) {
    return null;
  }

  return (
    <div className="pro-page_content">
      <ProMatches official_name={official_name} normalized_name={normalized_name} />
      <MediaQuery at="DESKTOP_LARGE">
        <ProSideColumn />
      </MediaQuery>
    </div>
  );
};

export default ProPageContent;
